
/* ==========================================================================
   Fonts
   ========================================================================== */


@mixin lato {
  font-family: 'Lato', sans-serif;
}
@mixin montserrat {
  font-family: 'Montserrat', sans-serif;
}



$teal: #0072bd;
$brandBlue: #13325a;
$grey: #f3f3f4;
$brandcolour01: #41c3d5;


$cdBlue: #29AAE2;


::selection { 
  background: #13325a;
  color:#fff;
}
::-moz-selection {
  background: #13325a;
  color:#fff;
}



/* ==========================================================================
   Colours
   ========================================================================== */


@mixin center {
    text-align: center;
}




/* ==========================================================================
   Variables
   ========================================================================== */
 