@import "./node_modules/bootstrap-scss/bootstrap";

@import "typography";
@import "variables";
@import "mixins";
@import "colors";
@import "animate";
@import "hover";

@import "global";


html, body {
  overflow-x: hidden;

}

html{
  scroll-behavior: smooth;
}

*:focus{
  outline: none !important;
}

.container{
  @media all and (max-width:991px){
    max-width: 100%;
  }
}


body {
  font-weight: normal;
  font-size: 15.5px !important;
  letter-spacing: .4px;
  line-height: 1.4;
  @include lato;
  color:#333333;

  //border: 15px solid #fff;
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;


button{
  @media all and (max-width: 991px){
    min-width: auto;
  }
}

  @media all and(max-width: 767px) {
    font-size: 14.5px !important;
    line-height: 1.55;
  }
}

.pull-right{
  float: right!important;
}

p {
  margin-top: 0;
  margin-bottom: 1.16rem;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.7;
  a{
  color:#333333;
    @include transition(all, 0.25s, ease-in-out);
    &:hover{
      color: #41c3d5;
      text-decoration: none;
    }
  }
}

@media all and (max-width: 991px){
  p{font-size: 1em!important;}
}

p.terms{
  text-align: right;
  margin-right: 0;
  font-size: 0.9em;
  color: #fff;
  margin-bottom: 0;
}

.btn{
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid $brandcolour01!important;
  text-transform: uppercase;
  color: #13325a;
  padding: 8px 20px 7px 20px;
  min-width: 190px;
  font-size: 0.85em;
  letter-spacing: 1px;
  border-radius:0;
  display: inline-block;
  text-decoration: none;
  @include lato;
  @include transition(all, 0.25s, ease-in-out);
  cursor: pointer;
  font-weight: 500;
  margin: 0 14px;
  margin-top: 40px;
  &:hover{
    background:$brandcolour01!important;
    color: #fff;
  }
}

@media all and (max-width: 1024px){
 .btn{
  margin-top: 10px;
 }
}

@media all and (max-width: 575px){
 .btn{
  margin-top: 0;
 }
}


@media all and (min-width: 992px){
  .container{
    max-width: 1280px!important;
    width: 100%!important;
  }
}

.wow {
  visibility: hidden;
}

#cookie-bar .cb-enable {
  background: #41c3d5 !important;
  color: #fff;
}

#cookie-bar .cb-enable:hover {
  background: #333 !important;
  color: #fff;
}


::selection {
  background: $brandBlue;
  color:#fff;
}
::-moz-selection {
  background: $brandBlue;
  color:#fff;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span{
  background-image: url('../img/fancybox_sprite.png')!important;
}

.scrollToTop {
    background: url("../img/btt-arrow-new.png") center no-repeat #133557;
    background-size: 24px;
    width: 48px;
    height: 48px;
    margin: 0; 
    float: left;
    display: none;
    transition: ease-in-out box-shadow 0.5s;
    font-size: 0px;
    position: fixed;
    right: 27px;
    bottom: 50px;
    z-index: 800;
    cursor: pointer;
    @include border-radius(100px);

  .tip {
    background: #333;
    display: none!important;
    width: 0;
    margin: 0;
    padding: 0;
    float: left;
    display: block;
    overflow: hidden;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    transition: ease-in-out width 0.15s;
    position: absolute;
    top: 10px;
    right: 58px;
    span {
      width: 100%;
      margin: 0;
      padding: 5px 4px 3px 4px;
      float: left;
      display: block;
      color: #fff;
      font-size: 12px;
      font-weight: 300;
      white-space: nowrap;
      text-align: center;
      text-transform: uppercase;
    }
  }


  &:hover {
    background-color: #333;
    .tip {
      width: 126px;
    }
  }
  @media (max-width:768px) {
    width:38px;
    height:38px;
    right:15px;
    bottom: 15px;
    span {
      display: none !important;
    }
  }
}




/* ==========================================================================
   Header / Nav
   ========================================================================== */

header.main {
  background: #fff;
  top: 0;
  z-index: 9999;
  width: 100%;
  left: 0;
  padding: 40px 25px 0 25px;
  border-bottom: 4px solid $brandcolour01;
  @media all and (max-width: 768px){
    border-bottom: 0;
  }
  .container-fluid {
        //position: initial;
  }

  .logo-sec{
    padding-left: 15px!important;
    padding-right: 15px!important;
  }

.trigger-sec{
  display: none;
  @media all and (max-width: 991px){
    display: initial;
  }
}


  a.logo {
    display: block;
    position: relative;
    z-index: 99999;
    img {
      max-width: 180px;
      width: 100%;
    }
  }

        .tel-mob{
        display: none;
        @media all and (max-width: 991px){
          display: initial;
          position: absolute;
          right: 50px;
          top: 26px;
          color: #0096a7;
          font-size: 2em;
        }
        @media all and (max-width: 575px){
          top: 17px;
        }

      }

        .tel-map{
        display: none;
        @media all and (max-width: 991px){
          display: initial;
          position: absolute;
          right: 20px;
          top: 24px;
          color: #0096a7;
          font-size: 2em;
        }
        @media all and (max-width: 575px){
          top: 15px;
        }

      }



  &.sticky {
    position: fixed !important;
    width: 100%;
    top: 0;
    z-index: 999;
    padding: 0 25px 0 25px;
    

    a.logo img {
      width: 100%;
      max-width: 140px;
      display: none;
    }
    nav.main{
      margin-top: 0;
    }
    .btn-cont{
      display: none;
    }

  }// &.sticky


.btn-cont{
  text-align: right;
  ul{
    margin-top: 12px;
  }

ul{
  list-style: none;
  margin-top: 27px;
  li{
    display: inline-block;
    margin-left: 20px;
    font-weight: 300;
      a.button {
        background: #fff;
        border: 2px solid $brandcolour01;
        text-transform: uppercase;
        color: #13325a;
        padding: 7px 20px;
        font-size: 0.85em;
        letter-spacing: 1px;
        display: inline-block;
        text-decoration: none;
        @include lato;
        @include transition(all, 0.25s, ease-in-out);
        cursor: pointer;
        margin-top: 19px;
        font-weight: 500;
        margin-left: 5px;

        &:hover {
          background: $brandcolour01;
          color: #fff;
        }
      }
      a.telephone{
        color: #333;
        font-size: 1.05em;
        @include transition(all, 0.25s, ease-in-out);
        i{
          font-size: 1.4em;
          color: #0296a6;
          top: 4px;
          left: -5px;
          position: relative;
          @include transition(all, 0.25s, ease-in-out);
        }
        &:hover{
          color: #000;
          text-decoration: none;
          i{
            color: #000;
          }
        }
      }
      &.day{
        display: none;
        &.active{
          display: initial;
        }
      }
  }
}


}





    /// cd-primary-nav-trigger
    .cd-primary-nav-trigger {
      color: #fff;
      font-weight: 500;
      position: relative;
      z-index: 99999;
      text-decoration: none;
      display: none;
      margin-left: 0;
      width: 100%;
      top: 21px;

      &.menu-is-open {
        .cd-menu-icon:before, .cd-menu-icon:after {
          background: #555 !important;
        }
      }

      .cd-menu-icon {
        /* this span is the central line of the menu icon */
        display: inline-block;

        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        width: 34px;
        height: 3px;
        background-color: #133457;
        -webkit-transition: background-color 0.3s;
        -moz-transition: background-color 0.3s;
        transition: background-color 0.3s;
        @include border-radius(200px);
        /* these are the upper and lower lines in the menu icon */
      }
    }// .cd-primary-nav-trigger
    .cd-primary-nav-trigger .cd-menu-icon::before, .cd-primary-nav-trigger .cd-menu-icon:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #133457;
      right: 0;
      -webkit-transition: -webkit-transform .3s, top .3s, background-color 0s;
      -moz-transition: -moz-transform .3s, top .3s, background-color 0s;
      transition: transform .3s, top .3s, background-color 0s;
      @include border-radius(200px);
    }
    .cd-primary-nav-trigger .cd-menu-icon::before {
      top: -9px;
    }
    .cd-primary-nav-trigger .cd-menu-icon::after {
      top: 9px;
    }

    .cd-primary-nav-trigger .cd-menu-icon.is-clicked {
      background-color: transparent !important;
    }
    .cd-primary-nav-trigger .cd-menu-icon.is-clicked::before, .cd-primary-nav-trigger .cd-menu-icon.is-clicked::after {
      background-color: #555;
    }
    .cd-primary-nav-trigger .cd-menu-icon.is-clicked::before {
      top: 0;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
      background: #fff;
    }
    .cd-primary-nav-trigger .cd-menu-icon.is-clicked::after {
      top: 0;
      -webkit-transform: rotate(225deg);
      -moz-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
      -o-transform: rotate(225deg);
      transform: rotate(225deg);
      background: #fff;
    }


  nav.main {
    margin: 0 auto;
    margin-top: 27px;
    position: initial;
    padding: 0!important;
    max-width: 1100px;
    @media all and (max-width: 1250px){
      padding: 0 15px !important;
    }
    ul.primary-nav {
      list-style: none;
      *zoom: 1;
      margin: 0;
      padding: 0;
      width: 100%;
      text-align: center;
      height: 41px;

      @include transition(all, 0.35s, ease-in-out);

      // Defualt link style
      a {
        padding: 10px 0;
        color: #13325a;
        font-size: 1em;
        letter-spacing: 1px;
        font-weight: 400;
        @include transition(all, 0.35s, ease-in-out);
      }

      @media all and(min-width: 992px) {
        .mobile {
          display: none;
        }
      }



      > li {
        display: inline-block;
        @include center;
        &#menu-item-76{width: 7%;}
        &#menu-item-126{width: 12%;}
        &#menu-item-125{width: 7%;}
        &#menu-item-124{width: 10%;}
        &#menu-item-123{width: 8%;}
        &#menu-item-122{width: 8%;}
        &#menu-item-121{width: 14%;}
        &#menu-item-120{width: 9%;}
        &#menu-item-119{width: 8%;}
        &#menu-item-1309{width: 8%;}

        &#menu-item-2623{width: 7%;}
        &#menu-item-3851{width: 9%;}
        &#menu-item-3855{width: 8%;}


        &#menu-item-79{
          display: none;
          @media all and (max-width: 991px){
            display: block;
          }
        }

        > ul{
          display: none;
        }

        > a {
          display: block;
          text-decoration: none;
          text-align: center;
          @include transition(all, 0.25s, ease-in-out);

          &:focus{
            background-color: transparent;
          }
        }// > a



 @media all and(min-width: 992px) {
    > a:hover, > a:focus, &.current_page_item > a, &.current-menu-parent > a, &.current-menu-ancestor > a, &:hover > a{
      position: relative;
      color: $brandcolour01;
      &:after{
        position: absolute;
        height: 6px;
        background: $brandcolour01;
        width: 62px;
        content: '';
        z-index: 9;
        left: 50%;
        bottom: 0;
        margin-left: -31px;
      }
    }
  }





        @media all and(min-width: 992px) {
          &:hover > a, &.current-menu-ancestor > a {
            color: $brandcolour01;
          }// &:hover > a
        }
      }// > li

      // Drop down style
      > li {
        position: relative;
        overflow: hidden;


          &.current-menu-parent{
            > a{
              color: $brandcolour01;
            }
          }

        .wrap {
          @media all and(min-width: 992px) {
            background: #133557;
            padding: 0 12px 16px 12px;
            opacity: 0;
            @include transition(all, 0.55s, ease-in-out);
            height: 0;
            width: 100%;
            padding-top: 40px;
            z-index: 9999;
            position: absolute;
            left: 0;
          }
        }// wrap

        > ul{
          max-width: 1100px;
          margin: 0 auto;
        }

         ul {
          padding: 0;
          margin: 0;
          list-style: none;
          max-width: 1100px;
          margin: 0 auto;


          li a {
            color: #fff;
            display: block;
            position: relative;
            z-index: 100;
            padding: 3px 5px;
            text-align: center;
            @include transition(all, 0.25s, ease-in-out);
            font-size: 0.991em;


            &:hover {
              color: #41c3d5;
              border-bottom: none;
              text-decoration: none;
            }
          }// li a
        }// ul
      }// End li

@media all and (min-width: 992px) {
  li#menu-item-125, li#menu-item-124, li#menu-item-123,
  li#menu-item-122, li#menu-item-121, li#menu-item-120, li#menu-item-3851{
   // position: initial;
    .wrap {
       // display: block;
       // opacity: 1;
       // height: auto;
       padding: 70px 75px;
      > ul {
        > li {
          width: 33.3333%;
          float: left;
          padding: 9px 8px;
          > a{
            display: none;
          }

a.menu-image-title-after{
        img {
          width: 100%;
          max-width: 100%;
          padding: 0;
          height: auto;
          background: #fff;
          padding: 15px 15px 10px 15px;
          @include transition(all, 0.25s, ease-in-out);
        }

        span {
          display: block;
          background: #fff;
          color: #13325a;
          padding: 15px;
          @include transition(all, 0.25s, ease-in-out);
          padding-top:0;
          @include center;
          &:hover {
            background: #333;
          }
        }
        &:hover{
          img{
            background: #41c3d5;
          }
          span {
            background: #41c3d5;
            color: #fff;
          }
        }
      }




          > a {
            font-weight: 600;
            color: #555;
          }

          > ul {
            opacity: 1;
            padding-top: 0;
            list-style: none;
            margin: 0;
            padding: 0;
            margin-top: 10px;

            li {
              width: 100%;
              margin-bottom: 22px;
              a {
                text-align: left;
              }
            }
          }
        }
      }// > ul
    }// .wrap
  }// li#menu-item-81

li#menu-item-119{
   // position: initial;
    .wrap {
      width: 100%;
      left: auto;
      right: 0;
      padding: 35px 30px;
     }
    }


  li#menu-item-83 {
    .wrap {
      padding: 20px 100px 25px 100px;
      > ul > li {
        width: 25%;
        float: left;
        padding: 9px 8px;
        &#menu-item-793{display: none;}
          > a{
            display: none;
          }
        img {
          width: 100%;
          max-width: 100%;
          padding: 0;
          height: auto;
          background: $brandBlue;
          padding: 5px;
          @include transition(all, 0.25s, ease-in-out);
          &:hover{
            filter: brightness(50%);
          }
        }

        span {
          display: block;
          background: $brandBlue;
          color: #fff;
          padding: 6px;
          @include transition(all, 0.25s, ease-in-out);
          text-transform: uppercase;
          @include center;

          &:hover {
            background: #333;
          }
        }

          > a {
            font-weight: 600;
            color: #555;
          }

          > ul {
            opacity: 1;
            padding-top: 0;
            list-style: none;
            margin: 0;
            padding: 0;
            margin-top: 10px;

            li {
              width: 100%;
              a {
                text-align: left;
              }
            }
          }

      }

    }// .wrap
  }// li#menu-item-83





}//media query 992

@media all and (max-width: 991px){
  li{
    ul.sub-menu{
      li#menu-item-307, li#menu-item-308, li#menu-item-321, li#menu-item-322, li#menu-item-329, li#menu-item-330, li#menu-item-342, li#menu-item-343, li#menu-item-345, li#menu-item-346, li#menu-item-352, li#menu-item-353{
        a.parent{
          display: none;
        }
      }
      li#menu-item-309, li#menu-item-323, li#menu-item-331, li#menu-item-344, li#menu-item-347, li#menu-item-354{
        display: none;
      }
    }
  }
}

@media all and(max-width: 1200px) {
  li#menu-item-725, li#menu-item-726, li#menu-item-83{
    .wrap{
      padding: 20px;
    }
  }
}


@media all and(min-width: 992px) {
      // Drop down effect
      > li:hover {
        position: initial;

        .wrap {
          opacity: 1;
          height: auto;
        }
      }
}


      &:before,
      &:after {
        content: " ";
        display: table;
      }
      &:after {
        clear: both;
      }
    }// End ul.primary-nav
  }// nav.main

  .ctalinks {
    display: none;
  }
}// &.header.main



@media all and(max-width: 1199px) {
  header.main {
    a.logo img {
      max-width: 180px;
      position: relative;
      top: 3px;
    }

    &.sticky nav.main ul.primary-nav {
      margin-left: 0;
    }

    nav.main {
      margin-top: 13px;

      ul.primary-nav {
        margin-left: 0%;
        width: 100%;
      }
    }
  }// header.main
}


@media all and(max-width: 1100px) {
  header.main {
    nav.main {
      ul.primary-nav {
        font-size: 0.85em;
        height: 38px;
      }
    }
  }
}


@media all and(max-width: 1050px) {
  header.main {
    nav.main {
      ul.primary-nav {
        width: 97%;
      }
    }
  }// header.main
}



@media all and(max-width: 1024px) {
  header.main {
      padding: 7px 25px 0 25px;
    a.logo img {
      top: 5px;
    }
  }// header.main
}


@media all and(max-width:991px) {
  body{
    padding-top:95px;
  }

  .no-scroll{
    overflow: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
  }

  header.main {
    position: fixed !important;
    width: 100%;
    z-index: 99;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: #fff;
    height: auto;
    padding: 7px 25px;
     a.logo {
      z-index: 99;
     }

    a.logo img {
      display: block;
      text-align: center;
      margin: 0 auto;
      max-width: 120px;
      top: 0;
    }

    .btn-cont{
      @media all and (max-width: 991px){
        display: none;
      }
    }


    .telephone-icon {
      color: #555;
      font-size: 1.82em;
      position: relative;
      top: 16px;
      float: right;
      margin-right: 30px;
    }

    /// cd-primary-nav-trigger
    .cd-primary-nav-trigger {
      display: block !important;
      top: 25px;
    }

      &.sticky nav.main {
        margin-top: 10px;
      }


    nav.main {
      margin: 0;
      background: none;

      .container, .row, .col-xs-12 {
        height: 100%;
      }

      ul.primary-nav {
        display: none;
      }

      &.active {
        padding: 25px 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        opacity: 0;

        -webkit-overflow-scrolling: touch;
        -webkit-backface-visibility: hidden;
        pointer-events: none;
        background: #133557;
        z-index: 700;
        opacity: 1;
        pointer-events: auto;
        width: 100% !important;
        max-width: 100% !important;

        //padding: 25px 30px;
        padding-top: 55px;
        overflow-x: scroll;

/*
       .inner {
          height: 98%;
          overflow-x: scroll;
          width: 100%;
        }//.inner

*/
        .cd-primary-nav-trigger {
          top: -25px;
          margin-right: -10px;
        }

        ul.primary-nav {
          display: block !important;
          padding-left: 20px;
        }
        .ctalinks {
          display: block !important;

        }
      }// &.active

      ul.primary-nav {
        clear: both;
        width: 100%;
        padding-top: 85px;
        height: auto;
        //height: auto;
       // margin-bottom: 10%;

        // Defualt link style
        li {
          width: 100% !important;
          display: block;
          float: none !important;
          margin: 0;
          padding-left: 0 !important;
          &#menu-item-779, &#menu-item-780, &#menu-item-781,
          &#menu-item-786, &#menu-item-787, &#menu-item-788, &#menu-item-793{
            a.parent{
              display: none;
            }
          }
          &#menu-item-782, &#menu-item-789, &#menu-item-794 {
            display: none;
          }

          ul {
            padding-left:0 !important;
          }

          a {
            color: #fff;
            text-align: left;
            font-size: 1.24em;
            padding: 8px 0;
            background: none !important;
            &:hover {
              color: #fff !important;
            }
          }
        }

        > li {
          float: none;
          border: 0;
          height: auto;

          > a {
            font-weight: 400;
          }

          a.parent {
            &::after {
              position: absolute;
              margin-left: 15px;
              color: #fff;
              font-family: FontAwesome;
              content: "\f105";
              font-size: 1.5em;
              top: 4px;
              @media all and (max-width: 768px){
                margin-left: 15px !important;
                width: auto;
              }
            }
          }// a.parent

           &.hover a.parent {
              color: #fff !important;
              font-weight: 700;

            &:after {
              content: "\f107";
            //  right: 16px;
              color: #fff !important;
            }// &.hover
           }

          // Drop down styles

          .wrap {
            display: none;

            img {
              display: none;
            }

            .parent {
              color: #fff !important;
              font-weight: 600 !important;

              span {
              color: #fff !important;
              font-weight: 600 !important;
              }
              &:after {
                display: none !important;
              }
            }

            li a {
              padding: 5px 0 !important;
            }
          }



          &.hover > ul , .nav li li.hover ul {  position: static; }
          &.hover > ul { clear: both; width: 100%; border: none;  }

          &.hover > ul , .nav li li.hover ul {  position: static; }
          &.hover > .wrap {
            clear: both;
            width: 100%;
            border: none;
            display: block;
            opacity: 1;
            position: relative;
            height: auto;
          }


          // Disabling the hover

        }// > li

        // Drop down styles
        li ul {
          background: none;
          padding: 0;
          padding-left: 12px;
          li a {
            text-align: left;
            color: inherit;
            font-size: inherit;
            color: #fff;
            padding: 7px 0;
            font-size: 1.243em;
          }
        }// li ul

      }// End ul.primary-nav
    }// nav.main

    .ctalinks {
      width: 100%;
      text-align: center;
      margin-top: 15px;

      a.button {
        width: 42%;
        float: none;
        display: inline-block;
        border: 2px solid #fff!important;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        font-size: 0.9em;
        background: none;
        text-decoration: none;
        @include border-radius(0);

        &.one {
          background: #fff;
          color: $teal;
          margin-right: 6%;
        }
      }// a.button
    }// .ctalinks
  }// header.main
}


@media all and(max-width: 768px) {
  header.main {
      padding: 7px 25px;
  }// header.main
}


@media all and(max-width: 575px) {
  body{
    padding-top:77px;
  }
  header.main {
    height: 77px;
    a.logo img {
      max-width: 90px;
      width: 90px;
      position: relative;
      top: 0;
    }
    .cd-primary-nav-trigger {
      margin-left: 0;
      top: 17px;
    }
    .telephone-icon {
      top: 15px;
      margin-right: -3px;
    }
  }
}

@media all and(max-width: 400px) {

  header.main {

    .cd-primary-nav-trigger {
      margin-left: 0;
    }
    .telephone-icon {
    }
  }
}



.home-slider{
  height: 74vh;
  .slick-slide{
      height: 74vh;
      background-size: cover!important;
      background-position: 50% 50%!important;
      position: relative;
      .inner{
        border-left: 12px solid $brandcolour01;
        padding-left: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
          -webkit-transform: translate(-100%, -50%);
          -ms-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%);
          @include transition(all, 0.25s, ease-in);
        max-width: 670px;
        h2{
          font-weight: 900;
          text-transform: uppercase;
          letter-spacing: 3px;
          color: #fff;
          font-size: 3em;
          a{
            color: #fff;
            text-decoration: none;
          }
        }
        h3{
          color: #fff;
          text-transform: uppercase;
          font-weight: 400;
          a{
            color: #fff;
            text-decoration: none;
          }
        }
        &:hover{
          border-left: 12px solid #045272;
        }
      }
  }
.slick-dots {
  display: flex;
  justify-content: center;
top: -50px;
position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
@media all and (max-width: 768px){
  top: -15px;
}
    li {
      margin: 0 10px;
    }

    button {
      display: block;
      width: 55px;
      height: 7px;
      padding: 0;
      border: none;
      background-color: #fff;
      text-indent: -9999px;
      @include transition(all, 0.25s, ease-in-out);
      &:hover{
        background-color: $brandcolour01;
      }
    }

    li.slick-active button {
      background-color: $brandcolour01;
    }

}

}

@media all and (max-width: 1200px){
.home-slider{
  .slick-slide{
      .inner{
          -webkit-transform: translate(-90%, -50%);
          -ms-transform: translate(-90%, -50%);
          transform: translate(-90%, -50%);
      }
    }
  }
}

@media all and (max-width: 1024px){
.home-slider{
  .slick-slide{
      .inner{
          h2{
            letter-spacing: 2px;
            font-size: 2em;
          }
          h3{
            font-size: 1.2em;
          }
      }
    }
  }
}

@media all and (max-width: 768px){
  .home-slider{
    height: 500px;
    .slick-slide{
      height: 500px;
      background-position: 80% 50% !important;
      .inner{
        border-left: 6px solid #41c3d5;
      }
    }
  }
}

@media all and (max-width: 650px){
  .home-slider{
     height: 400px;
    .slick-slide{
      height: 400px;
      background-position: 80% 50% !important;
      .inner{
        h2{
          font-size: 1.6em;
        }
        h3{
          font-size: 1em;
        }
      }
    }
  }
}

@media all and (max-width: 575px){
  .home-slider{
    height: 300px;
    .slick-slide{
      height: 300px;
      background-position: 70% 50% !important;
      .inner{
        left: 20px;
          -webkit-transform: translate(0%, -50%);
          -ms-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
padding-left: 10px;
max-width: 200px;
        h2{
          font-size: 1.2em;
        }
        h3{
          font-size: 1em;
        }
      }
    }
  }
}

.titlesec{
  h1{
    position: relative;
    color: #045272;
    font-size: 1.8em;
    margin-bottom: 48px;
    &::after{
      height: 5px;
      width: 64px;
      background: #0296a6;
      position: absolute;
      left: 50%;
      bottom: -22px;
      margin-left: -32px;
      z-index: 9;
      content: '';
    }
  }
  h2{
    position: relative;
    color: #045272;
    font-size: 1.8em;
    margin-bottom: 48px;
    &::after{
      height: 5px;
      width: 64px;
      background: #0296a6;
      position: absolute;
      left: 50%;
      bottom: -22px;
      margin-left: -32px;
      z-index: 9;
      content: '';
    }
  }
}

@media all and (max-width: 768px){
.titlesec{
  h1, h2{
    font-size: 1.6em;
  margin-bottom: 35px;
    &::after{
      height: 3px;
      bottom: -15px;
    }
    }
  }
}


.info-sec{
  text-align: center;
  padding: 70px 15px;
  @media all and (max-width: 1024px){
    padding: 30px 15px 30px 15px;
  }
  &.awards{
    background: #008ea1;
    .titlesec{
      margin-bottom: 60px;
      @media all and (max-width: 1024px){
        margin-bottom: 0;
      }
      @media all and (max-width: 767px){
        margin-bottom: 40px;
      }
      
      h2{
        color: #fff;
        &:after{
          background: #fff;
        }
      }
    }
    .award-list{
      float: left;
      margin-top: 20px;
      .imageouter{
        padding:0;
      }
      img{
        width: 100%;
        @include transition(all, 0.25s, ease-in);
        &:hover{
          filter: brightness(50%);
        }
      }
      @media all and (max-width: 1024px){
        margin-top: 10px;
        margin-bottom: 10px;
      }

      @media all and (max-width: 575px){
        margin-top: 10px;
        margin-bottom: 10px;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
      }

    }
    .modal{
      .modal-dialog{
        max-width: 550px;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%)!important;
        transform: translate(-50%,-50%)!important;

    
        .modal-content{
          aside{
            width: 100%;
            padding: 0 15px;
            img{
              width: 100%;
            }
          }
        }
        @media all and (max-width: 650px){
          max-width: 75%;
        }
      }
    }
  }
  &.why{
    overflow: hidden; 
  }
  &.review{
    padding: 150px 15px;
    background-size: cover!important;
    background-position: 50% 50%!important;
    @media all and (max-width: 768px){
      padding: 75px 15px;
    }
  }
  a.btn{
    @media all and (max-width: 575px){
      margin: 5px 0;
      display: block;
    }
  }
}

.treatment-sec{
  text-align: center;
  padding: 85px 15px;
  p{
    &:last-of-type{
      margin-bottom: 0;
    }
  }
  &.feeds{

    .titlesec{
      h2{
        color: #333;
        @media all and (max-width: 575px){
          font-size: 1.4em;
        }
      }
    @media all and (max-width: 767px){
      display: block;
      padding-top: 65px;
      }


    }
    @media all and (max-width: 767px){
      padding-bottom:10px;
      .col-xs-6{
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
        &:first-of-type{
          padding-right: 7.5px;
        }
        &:last-of-type{
          padding-left: 7.5px;
        }

      }
    }
  }
  @media all and (max-width: 1024px){
    padding: 30px 0;
  }
}




.consultation-process{
  padding: 50px 15px;
  background: #eeeeee;
  text-align: center;
  .info{
    padding: 0 50px;
    ul{
      list-style: none;
      margin-top: 60px;
      padding-left:0;
      li{
        display: inline-block;
        margin: 0 20px;
        a{
          color: #0296a6;
          letter-spacing: 0.5px;
          @include transition(all, 0.15s, ease-in);
          &:hover{
            color: #333;
            text-decoration: none;
          }
        }
      }
    }
  }
  .vid-sec{
    align-self: center;
    @media all and (max-width: 767px){
      margin-top: 10px;
    }
  }
  .titlesec{
    margin-top: 65px;
  }
  .video-outer{
    height: 440px;
    background-position: 50% 50%!important;
    background-size: cover!important;
    position: relative;
    .video-inner{
      position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        a.btn{
          margin: 0;
        }
    }
  }
}

@media all and (max-width: 1200px){
.consultation-process{
    .info{
      padding: 0 15px;
      ul{
        margin-top: 30px;
      }
    }
  }
}


@media all and (max-width: 1024px){
.consultation-process{
  padding: 30px 0;
    .titlesec{
        margin-top: 0;
      }
    .video-outer{
      height: 400px;
    }
    .info{
      ul{
        li{
          margin: 0 5px;
          a{
            font-size: 0.9em;
          }
        }
      }
    }
  }
}

@media all and (max-width: 768px){
.consultation-process{
    .video-outer{
      margin-top: 0;
    }
    .info{
      padding-top: 0;
      ul{
        li{
          margin: 0 5px;
          a{
            font-size: 1em;
          }
        }
      }
    }
  }
}

@media all and (max-width: 767px){
.consultation-process{
  padding: 30px 0 35px 0;
    .info{
      padding-top: 0;
      ul{
        margin-top: 15px;
      }
    }
  }
}


@media all and (max-width: 650px){
.consultation-process{
    .video-outer{
      height: 275px;
    }
    .info{
      ul{
        li{
          margin: 5px 15px;
          display: block;
        }
      }
    }
  }
}

.treatments-list{
  padding-bottom: 110px;
  .treatmentSlider{
    .slick-slide{
      width: 32.8333%;
      text-align: center;
      margin: 0 0.25%;
      .img-sec{
        height: 330px;
        position: relative;
        background: red;
        background-position: 50% 50%!important;
        background-size: cover!important;
        a.btn{
          margin: 0;
      position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        }
      }
      .info{
        margin-top: 50px;
        text-align: left;
        .slide-title{
          border-left: 5px solid #045471;
          padding-left: 15px;
          text-align: left;
          padding-bottom: 5px;
          padding-top: 5px;
          margin-bottom: 20px;
          h2{
            margin-top: 0;
            text-transform: uppercase;
            font-size: 1.2em;
            font-weight: 700;
            color: #045471;
            margin-bottom: 5px;
          }
          h3{
            margin-top: 0;
            text-transform: uppercase;
            font-size: 1.2em;
            font-weight: 500;
            color: #045471;
          }
        }
        ul{
          padding-left:0;
          list-style: none;


                li{
                  font-size: 1.05em;
                  font-weight: 500;
                  letter-spacing: .5px;
                  margin-bottom: 6px;
                  display: block;
                  list-style: none;
                  background-size: 22px 17px;
                  padding-left: 0;
                   margin-top: 0;
                  width: 100%;
                  padding-left: 15px;
                    &:before{
                      content: '';
                      width: 20px;
                      height: 15px;
                      //background: url('../img/home/treatments/treatment2-arrow.png') no-repeat 0 100%;
                      background: url('../img/tick.png');
                      background-repeat: no-repeat;
                      display: inline-block;
                      background-size: cover;
                      position: relative;
                      z-index: 1;
                      top: 0;
                      left: -15px;
                    }
                }


        }
      }
    }
    .slick-dots {
      display: block;
      margin: 0;
      padding: 0;
      list-style-type: none;
      text-align: center;
      margin-top: 40px;
        li {
          margin: 0 3px;
          display: inline-block;
        }
        button {
          display: block;
          width: 15px;
          height: 15px;
          padding: 0;
          border: none;
          border-radius: 100%;
          background-color: #a1d5db;
          text-indent: -9999px;
          @include transition(all, 0.25s, ease-in-out);
          &:hover{
            background-color: #13325a;
          }
        }
        li.slick-active button {
          background-color: #13325a;
        }
    }

  }
}

@media all and (max-width: 1024px){
  .treatments-list{
    padding-bottom: 25px;
  .treatmentSlider{
    .slick-slide{
      .img-sec{
        height: 250px;
        }
        .info{
          margin-top: 30px;
          ul{
            li{
              font-size: 1em;
            }
          }
        }
      }
      .slick-dots {
        margin-top: 20px;
      }
    }
  }
}

@media all and (max-width: 650px){
  .treatments-list{
  .treatmentSlider{
    .slick-slide{
        width: 100%;
        margin: 0%;
      }
      .slick-dots {
        display: none;
      }
    }
  }
}


.book-appointment-sec{
  text-align: center;
  padding-top:70px;
  padding-bottom: 75px;
  background: #008ea1;

p.terms{
  text-align: right;
  margin-right: 0;
  font-size: 0.9em;
  color: #fff;
  margin-bottom: 0;
  margin-top: 45px;
  padding-right: 5px;
  @media all and (max-width: 1024px){
    margin-top: 5px;
  }
}

        .slide-title{
          border-left: 10px solid #fff;
          padding-left: 15px;
          text-align: left;
          padding-bottom: 7px;
          padding-top: 7px;
          margin-bottom: 20px;
          width: auto;
          display: inline-block;
          h2{
            margin-top: 0;
            text-transform: uppercase;
            font-size: 1.8em;
            font-weight: 700;
            color: #fff;
            margin-bottom: 5px;
          }
          h3{
            margin-top: 0;
            text-transform: uppercase;
            font-size: 1.8em;
            font-weight: 500;
            color: #fff;
            margin-bottom: 0;
          }
        }

form{
  margin-top: 20px;
  br{
    display: none;
  }
  .col-lg-4{
    padding: 0 20px!important;
  }
  .col-lg-8{
    padding: 0!important;
  }

  .col-lg-6, .col-lg-12 {
    padding: 0 20px!important;
    float: left;
  }




  input.wpcf7-text, select, textarea {
    width: 100%;
    background: #fff;
    color: #13325a;
    display: block;
    border: none;
    font-weight: 400;
    font-size: 1em;
    letter-spacing: 1px;
    padding: 13px 17px;
      -webkit-appearance: none;
  -webkit-border-radius: 0px;
  text-transform: uppercase;

    &::placeholder {
      font-size: 1em;
      color: #13325a;
      opacity: 1;
    }
    &.wpcf7-not-valid{
      border: 1px solid red;
    }
    @media all and (max-width: 768px){
      font-size: 0.9em;
      padding: 10px 17px;
    }
  }

  input.wpcf7-text, select {
    margin-bottom: 35px;
  }
  select {
    cursor: pointer;

    &:after {
      font-family:'FontAwesome';
      content:"\f107";
    }

  }

  textarea {
    height: 128px;
    max-height: 128px;
    min-height: 128px;
  }


       input[name=tel-982] {
        background: #fff url(../img/tel.png) no-repeat;
        background-size: 16px 16px;
        background-position: 10px 15px;
        padding-left: 40px !important;
      }

       select[name=menu-161] {
        background: url(../img/interested.png) 10px 15px no-repeat, url(../img/drop-down-arrow.png) 95% 15px no-repeat !important;
        background-size: 16px 16px !important;
        background-position: 10px 15px;
        padding-left: 40px !important;
        background-color: #fff!important;
        margin-bottom: 25px;
      }

       select[name=menu-456] {
        background: url(../img/pref-contact.png) 10px 10px no-repeat, url(../img/drop-down-arrow.png) 95% 10px no-repeat !important;
        background-size: 16px 16px !important;
        background-position: 10px 15px;
        padding-left: 40px !important;
        background-color: #fff!important;
      }


      textarea[name=textarea-343] {
        background: #fff url(../img/message.png) no-repeat;
        background-size: 16px 16px;
        background-position: 10px 15px;
        padding-left: 40px !important;
      }

      textarea[name=textarea-344] {
        background: #fff url(../img/message.png) no-repeat;
        background-size: 16px 16px;
        background-position: 10px 15px;
        padding-left: 40px !important;
        display: none;
      }

      input[name=full-name] {
        background: #fff url(../img/name.png) no-repeat;
        background-size: 16px 16px;
        background-position: 10px 15px;
        padding-left: 40px !important;
      }

      input[name=email-829] {
        background: #fff url(../img/email.png) no-repeat;
        background-size: 16px 16px;
        background-position: 10px 15px;
        padding-left: 40px !important;
      }


      .custom-select {
        position: relative;
        background: url(../img/interested.png) 10px 16px no-repeat, url(../img/drop-down-arrow.png) 97% 15px no-repeat !important;
        background-size: 16px 16px !important;
        background-position: 10px 15px;
        padding-left: 40px !important;
        background-color: #fff!important;
        margin-bottom: 25px;
        border-radius: 0;
        border: none;
        height: 47px;
        color: #13325a;
        border: none;
        font-weight: 400;
        font-size: 1em;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding-top: 8px;
        padding-right: 0;
        select {
          display: none; /*hide original SELECT element:*/
        }
      }
      .select-selected {
        text-align: left;
        margin: 4px 0;
        padding: 0;

      }
      /*point the arrow upwards when the select box is open (active):*/
      .select-selected.select-arrow-active:after {
        //border-color: transparent transparent #fff transparent;
        top: 12px;
        transform: rotate(180deg);
      }
      /*style the items (options), including the selected item:*/
      .select-items div,.select-selected {
        cursor: pointer;
        user-select: none;
      }
      /*style items (options):*/
      .select-items {
        position: absolute;
        background-color: #fff;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        text-align: left;
        padding: 10px 15px;
        div {
          border-top: 1px solid #FEFEFE;
        }
      }
      /*hide the items when the select box is closed:*/
      .select-hide {
        display: none;
      }
      .select-items div:hover, .same-as-selected {
        color: #008ea1;
      }



  label {
    margin-top: 0;
    font-size: 1.1em;
    font-weight: 300;
    letter-spacing: 0.5px;
    color: #fff;
    text-align: left;
    display: block;
     @media all and (max-width: 768px){
      text-align: center;
     }
    @media all and (max-width: 575px){
      display: block;
    }
  }

a.policy-link{
  display: inline-block;
  color:#fff;
  font-size: 0.8em;
  font-weight: 300;
  float: left;
  margin-left: 30px;
  margin-top: 3px;
  &:hover{
    color: #333;
    text-decoration: none;
  }
  @media all and (max-width: 768px){
    float: none;
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
}

.btn{
  background: transparent;
  color: #fff;
  min-width: 305px;
  margin-top: 45px;
  @include transition(all, 0.25s, ease-in);
  &:hover{
    background: #41c3d5!important;
    color: #fff;
    text-decoration: none;
  }
}

  .radio-729 {
width: auto;
text-align: left;
display: inline-block;
float: left;
    input {
      cursor: pointer;
      margin-left: 5px;
      position: relative;
      top: -3px;
      @media all and (max-width: 767px){
        top: 2.5px;
      }
    }
    span {
      position: relative;
      left: 2px;
      font-size: 1em;
      text-transform: uppercase;
      top: 0px;
      color: #fff;
      font-weight: 300;
      letter-spacing: 1px;
        &.first{
          margin-left: 0!important;
        }
    }
    @media all and (max-width: 768px){
      float: none;
    }
    @media all and (max-width: 575px){
      .wpcf7-list-item{
        margin: 0;
        margin: 0 10px!important;
      }
    }
  }

  .g-recaptcha{
      text-align: center;
      position: relative;
      left: 50%;
      margin-left: -152px;
      margin-top: 20px;
      margin-bottom: 20px;
  }

span.wpcf7-not-valid-tip{
  display: none;
}

.button{
  padding: 10px 40px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-image: linear-gradient(90deg, #888888, #e7e7e7, #888888) 1 !important;
    &:hover{
      border: 2px solid #888!important;
    }
}


.wpcf7-validation-errors{
  margin: 0;
  background: red;
  border: 2px solid red;
  color: #fff;
  padding: 10px 20px;
  font-weight: 300;
  margin-top: 15px;
}

  }// .button
  .ajax-loader{
    display: none!important;
    text-align: center;
    margin: 5px auto!important;
    &.is-active{
      display: block!important;

    }
  }



}//book-appointment-sec

@media all and (max-width: 1024px){
  .book-appointment-sec{
    padding: 30px 5px;
    .slide-title{
      border-left: 5px solid #fff;
      h2{
        font-size: 1.4em;
      }
      h3{
        font-size: 1.4em;
      }
    }
    form{
      margin-top: 20px;
      .col-lg-4{
        padding: 0 10px!important;
      }
      .col-lg-8{
        padding: 0!important;
      }

      .col-lg-6, .col-lg-12 {
        padding: 0 10px!important;
        float: left;
      }

      input.wpcf7-text, select {
        margin-bottom: 25px;
      }
      label{
        margin-top: -15px;
        font-size: 1em;
      }
      .radio-729{
          span{
            font-size: 0.95em;
          }
        }
        .btn{
          margin-top: 35px;
        }
    }
  }
}

@media all and (max-width: 991px){
  .book-appointment-sec{
      form{
        textarea[name=textarea-343] {
          display: none;
        }
          input.wpcf7-text, select, .custom-select {
            margin-bottom: 15px!important;
          }
        textarea[name=textarea-344] {
          display: block;
        }
        label{
          margin-top: 15px;
        }
      }
    }
  }


@media all and (max-width: 375px){
  .book-appointment-sec{
    .slide-title{
      h2{
        font-size: 1.2em;
      }
      h3{
        font-size: 1.2em;
      }
    }
      form{
        .btn{
          min-width: 250px;
        }
      }
    }
  }



.reviewSlider{
  margin-top: 90px;
  .slick-slide{
    p{
      font-size: 1.6em;
      font-style: italic;
    }
    span.name{
      display: block;
      margin-top: 25px;
      font-size: 1.2em;
      font-weight: 400;
    }
  }
    .slick-dots {
      display: block;
      margin: 0;
      padding: 0;
      list-style-type: none;
      text-align: center;
      margin-top: 24px;
        li {
          margin: 0 10px;
          display: inline-block;
        }
        button {
          display: block;
          width: 8px;
          height: 8px;
          padding: 0;
          border: none;
          border-radius: 100%;
          background-color: #13325a;
          text-indent: -9999px;
          &:hover{
            background-color: #a1d5db;
          }
        }
        li.slick-active button {
          background-color: #a1d5db;
        }
    }
}

@media all and (max-width: 1024px){
  .reviewSlider{
    margin-top: 10px;
    p{
      font-size: 1.2em!important;
    }
  }
}

.reviewlinks{
  list-style: none;
  margin-top: 70px;
  margin-bottom: 0;
  li{
      display: inline-block;
      margin: 0 35px;
      font-size: 0.9em;
      font-weight: 700;
    a{
      text-transform: uppercase;
      color: #008ea0;
      @include transition(all, 0.25s, ease-in);
      &:hover{
        color: #333;
        text-decoration: none;
      }
    }
  }
}

@media all and (max-width: 1024px){
  .reviewlinks{
    margin-top: 20px;
  }
}

@media all and (max-width: 650px){
  .reviewlinks{
      padding: 0;
      li{
        display: block;
        margin:5px 0;
      }
  }
}

.cta-sec{
  background: #025271;
  padding: 150px 15px;
  .split{
    padding: 0 45px;
  }
  .slide-title{
    border-left: 4px solid #42c3d5;
    padding-left: 25px;
    text-align: left;
    padding-bottom: 2px;
    padding-top: 2px;
    margin-bottom: 40px;
    width: auto;
    display: inline-block;
    h2{
      margin-top: 0;
      text-transform: uppercase;
      font-size: 1.6em;
      font-weight: 700;
      color: #fff;
      margin-bottom: 5px;
    }
    h3{
      margin-top: 0;
      text-transform: uppercase;
      font-size: 1.6em;
      font-weight: 500;
      color: #fff;
      margin-bottom: 0;
    }
  }
  p{
    color: #fff;
  }
  a{
    text-transform: uppercase;
    display: block;
    margin-top: 50px;
    font-size: 0.9em;
    color: #81edeb;
    font-weight: 700;
    @include transition(all, 0.25s, ease-in);
    &:hover{
      color: #333;
      text-decoration: none;
    }
  }
}

@media all and (max-width: 1024px){
  .cta-sec{
    padding: 45px 5px;
    .split{
      padding: 0 10px;
      .slide-title{
        margin-bottom: 25px;
        h2{
          font-size: 1.4em;
        }
        h3{
          font-size: 1.4em;
        }
      }
      a{
        margin-top: 30px;
      }
    }
  }
}

@media all and (max-width: 768px){
  .cta-sec{
    padding: 50px 5px;
    .split{
      padding: 0 10px;
      .slide-title{
        margin-bottom: 25px;
        h2{
          font-size: 1.1em;
        }
        h3{
          font-size: 1.1em;
        }
      }
      a{
        margin-top: 0;
      }
    }
  }
}

@media all and (max-width: 650px){
    .cta-sec{
      .split{
      width: 100%;
      flex: none;
      max-width: 100%;
      &:last-of-type{
        margin-top: 30px;
      }
    }
  }
}


.blogSlider{
margin-top: 15px;
.slick-slide{
  height: 290px;
  @media all and (max-width: 1024px){
    height: 229px;
  }
  @media all and (max-width: 768px){
    height: 165px;
  }
  img{
    width: 100%;
    height: 100%;
  }
}
    .blog-image{
      width: 50%;
      float: left;
      height: auto;
      overflow: hidden;
      height: 290px;
      img{
        width: 100%;
        @include transition(all, 0.25s, ease-in);
        object-fit: cover;
        height: 100%;
        opacity: 1;
      }
      &:hover{
        img{
          transform: scale(1.1);
        }
      }
    }
    .blog-info{
        padding: 0;
        text-align: left;
        width: 50%;
        float: left;
        padding-left: 25px;
      a{
        color: #333333;
        &:hover{
          text-decoration: none;
        }
      }
      h4{
        margin: 0;
        font-weight: 500;
        font-size: 1.2em;
        color:#333;
        margin-top: 10px;
        margin-bottom: 5px;
      }
      p{
      margin: 0;
      margin-bottom: 0px;
      color:#333;
      font-weight: 700;
      font-size: 0.85em;
      margin-bottom: 10px;
      &.txt{
        font-weight: 300;
        line-height: 1.65;
      }
      }
    }

  .slick-slide{
    position: relative;
  }


    .slick-dots {
      display: block;
      margin: 0;
      padding: 0;
      list-style-type: none;
      text-align: center;
      margin-top: 30px;
        li {
          margin: 0 10px;
          display: inline-block;
        }
        button {
          display: block;
          width: 8px;
          height: 8px;
          padding: 0;
          border: none;
          border-radius: 100%;
          background-color: #13325a;
          text-indent: -9999px;
          &:hover{
            background-color: #a1d5db;
          }
        }
        li.slick-active button {
          background-color: #a1d5db;
        }
    }
}

.img-split{
  display: none;
}
@media all and (max-width: 767px){
   .img-split{
      display: inline-block;
      width: 100%;
      float: left;
      height: auto;
      overflow: hidden;
      margin-bottom: 20px;
    img{
      width: 100%;
    }
  }
}

@media all and (max-width: 575px){
   .img-split{
      height: auto;
    img{
      width: 100%;
      height: auto;
    }
  }
}

@media all and (max-width: 1024px){
.blogSlider{
    .blog-image{
      height: 230px;

      }
      .blog-info{
        p{
          &.txt{
            height: 130px;
            overflow: hidden;
            position: relative;
          }
        }
      }
    }
  }

@media all and (max-width: 768px){
.blogSlider{
    .blog-image{
      height: 168px;
      }
      .blog-info{
        padding: 0 0 0 15px;
        h4{
          margin-top: 0;
        }
        p{
          &.txt{
            height: 80px;
          }
        }
      }
    }
  }

@media all and (max-width: 767px){
.blogSlider{
    display: none!important;
    }
  }



.tourSlider{
margin-top: 15px;
  .slick-slide{
    position: relative;
    img{
      width: 100%;
    }
  }
    .slick-dots {
      display: block;
      margin: 0;
      padding: 0;
      list-style-type: none;
      text-align: center;
      margin-top: 30px;
        li {
          margin: 0 10px;
          display: inline-block;
        }
        button {
          display: block;
          width: 8px;
          height: 8px;
          padding: 0;
          border: none;
          border-radius: 100%;
          background-color: #13325a;
          text-indent: -9999px;
        }
        li.slick-active button {
          background-color: #a1d5db;
        }
    }

}

/* ==========================================================================
   Why choose
   ========================================================================== */

.whychoose-gallery{
 margin-top: 50px;
 overflow: hidden;
 @media all and (max-width: 1024px){
   margin-top: 10px;
 }

 .image-sec{
   float: left;
   padding: 0 10px;
   .left-top{
     height: 265px;
     width: 100%;
     background: grey;
     background-size: cover!important;
     background-position: 50% 50%!important;
     margin-bottom: 15px;
   }
   .left-bottom{
     height: 520px;
     width: 100%;
     background: grey;
     background-size: cover!important;
     background-position: 50% 50%!important;
     @media all and (max-width: 575px){
       margin-bottom: 15px;
     }
   }
   .right-top{
     height: 450px;
     width: 100%;
     background: grey;
     background-size: cover!important;
     background-position: 50% 50%!important;
     margin-bottom: 15px;
   }
   .right-bottom-left{
     height: 335px;
     width: 100%;
     background: grey;
     background-size: cover!important;
     background-position: 50% 50%!important;
     float: left;
     border-right: solid 10px #fff;
     @media all and (max-width: 575px){
       border: none;
       margin-bottom: 15px;
     }
   }
   .right-bottom-right{
     height: 335px;
     width: 100%;
     background: grey;
     background-size: cover!important;
     background-position: 50% 50%!important;
     float: left;
     border-left: solid 10px #fff;
     @media all and (max-width: 575px){
       border: none;
     }
   }
   @media all and (max-width: 575px){
     padding: 0;
   }
 }
}

section.popularTreatments-container {
  background: linear-gradient(to top, #008ea1 65%, white 0%);
  padding: 0 15px 70px 15px;
  @media all and (max-width: 1024px){
    padding: 20px 15px 40px 15px;
  }
  @media all and (max-width: 768px){
    padding: 30px 15px;
  }
  text-align: center;


  @media all and(min-width: 992px) {
    .box-0, .box-1 {
      margin-top: 0;
    }
  }

  .img-container {
    position: relative;
    z-index: 9;
  }


  img {
    width: auto;
    max-width: 100%;
  }

  h2{
    position: relative;
    color: #fff;
    font-size: 1.8em;
    margin-top: 40px;
    @media all and (max-width: 1024px){
      margin-top: 30px;
    }
  }
  h3{
    position: relative;
    color: #fff;
    font-size: 1.4em;
    font-style: italic;
  }


  p {
    color: #fff;
  }

}// section.popularTreatments-container


@media all and (max-width: 991px) {
  section.popularTreatments-container {
    background: #008ea1;
    .img-container {
      overflow: hidden;
      position: relative;
      height: auto;
    }

    h2 {
      margin-top: 20px;
    }
    h3 {
      margin-bottom: 15px;
    }

    .button {
      margin-bottom: 30px;
    }
  }// section.popularTreatments-container
}

@media all and (max-width: 991px) {
  section.popularTreatments-container {
    .box-0{
      margin-bottom: 0;
    }
  }
}

@media all and (max-width: 575px) {
  section.popularTreatments-container {
    .box-0{
      margin-bottom: 30px;
    }
  }
}


  /* ==========================================================================
   Footer
   ========================================================================== */



footer{
  background: #133557;
  padding: 60px 15px 60px 15px;
  text-align: center;
  clear: both;
  @media all and (max-width: 768px){
    padding: 30px 15px 10px 15px;
  }

  .openinghours{
    ul{
      padding: 0 35px;
    }
    @media all and (max-width: 1024px){
      width: 33.3333%;
      ul{
        padding: 0!important;
      }
    }
    @media all and (max-width: 768px){
      display: none;
    }
  }

.links{
    @media all and (max-width: 1024px){
      width: 33.3333%;
    }
    @media all and (max-width: 768px){
      display: none;
    }
}

h3{
font-size: 1em;
font-weight: 700;
color: #fff;
margin-bottom: 25px;
}

  p{
    color: #fff;
    font-weight: 300;
    margin-bottom: 0;
    font-size: 0.9em;
    line-height: 1.7;
    &.subsec{
      margin-top: 20px;
    }
    a{
      color: #fff;
      font-size: 1.3em;
      &:hover{
        color: #41c3d5;
        text-decoration: none;
      }
    }
    &.directions{
      margin-top: 20px;
      a{
        display: block;
        &:hover{
          color: #41c3d5;
        }
      }
      span{
        font-size: 1.5em;
        position: relative;
        top: 4px;
        &:hover{
          color: #41c3d5;
        }
      }
    }
  }
  h4{
    margin-top: 0;
    font-size: 0.9em;
    color: #fff;
    font-weight: 700;
  }
  ul{
    list-style: none;
    padding-left:0;
    li{
      font-weight: 300;
      //font-size: 0.95em;
      line-height: 1.7;
      margin-bottom: 2px;
      color:#fff;
      width: 100%;
      font-size: 0.9em;
      margin-bottom: 0;
      a{
        color: #fff;
        &:hover{
          color: #41c3d5;
          text-decoration: none;
        }
      }
      &.active{
        color: #41c3d5;
      }
      .day{
        width: 50%;
        display: inline-block;
        text-align: left;
      }
      .times{
        width: 50%;
        display: inline-block;
        text-align: right;
      }
    }
  }

.social{
  text-align: center;
  @media all and (max-width: 850px){
    display: none;
  }
  @media all and (max-width: 768px){
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 25px;
  }
}


.contactinfo{
    @media all and (max-width: 1024px){
      width: 33.3333%;
    }
  @media all and (max-width: 768px){
    width: 100%;
    margin: 0 auto;
    float: none;
    text-align: center;
    h3{
      display: none;
    }
  }
}


  ul.sm-links {
    display: table;
    margin: 0;
    list-style: none;
    width: 100%;
    //margin-top: 30px;
    margin-bottom: 15px;

    li {
      width: auto;
      display: inline-block;
      margin: 0 10px;
    }
    li a {
      //background: url(../img/sm-icons.png) no-repeat;
      //width: 42px;
      //height: 28px;
      display: block;
      content: "";
      font-size: 2.5em;

      &:before, &:after {
        display: none;
      }

      &.tw {
        background-position: 6px 0;
      }
      &.ig {
        background-position:-42px 0px;
      }
      &.yt {
        background-position: -96px 0;
      }
      &.fb {
        background-position: -154px 0px;
        width: 20px;
      }
      &.rv {
        background-position: -193px 0px;
        width: 30px;
      }
      &:hover{
        //background: url(../img/sm-icons-hover.png) no-repeat;
          &.tw {
            background-position: 6px 0;
          }
          &.ig {
            background-position:-42px 0px;
          }
          &.yt {
            background-position: -96px 0;
          }
          &.fb {
            background-position: -154px 0px;
            width: 20px;
          }
          &.rv {
            background-position: -193px 0px;
            width: 30px;
          }
      }
    }





    @media all and (max-width: 768px){
      margin: 0 auto 0 auto;
      float: none;

    }

    &.footer{
      display: none;
    }
  }// ul.sm-links


  .copyright{
    text-align: center;
    margin-top: 10px;
    p{
      font-size: 0.8em!important;
      margin-bottom: 0;
      a{
        font-size: 1em;
      }
    }
    a.cd-link{
      &:hover{
        text-decoration: underline;
        color: #fff;
      }
    }
    @media all and (max-width: 768px){
      margin-top: 20px;
    }
  }
}



  /* ==========================================================================
   Main
   ========================================================================== */

.main-banner{
  height: 74vh;
  background-size: cover!important;
  background-position: 50% 50%!important;
    img{
      display: none;
    }
}
@media all and (max-width: 1024px){
  .main-banner{
    height: auto;
    background: none!important;
    img{
      display: block;
      width: 100%;
    }
  }
}


.treatment-process{
  padding: 50px 15px 120px 15px;
  background: #fff;
  text-align: center;
  clear: both;
  float: left;
  width: 100%;
  .container{
    max-width: 1440px!important;
  }
  .row{
    display: block;
    .split{
      float: left;
    }
  }
  .info{
    padding: 0 50px;
    position: relative;
    height: 560px;
    overflow: hidden;
    display: flex;
    .inner{
      align-self: center!important;
        max-height: 380px;
        z-index: 1;
        overflow:hidden;
        width: 100%;
        .mCustomScrollBox {
          max-width: 100%;
          margin: 0 auto;
        }// .mCustomScrollBox
        .mCSB_scrollTools {
          height: 100%;
          top: 0;
          right: -55px;
          @media all and (max-width: 1200px){
            right: -20px;
          }




        }// .mCSB_scrollTools 
  
        .mCSB_scrollTools .mCSB_draggerRail {
          width:7px;
        }
        .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
          width:7px;
          background: #0796a6;
        }
        p{
          &:last-of-type{
            margin-bottom: 0;
          }
        }



    }
    ul{
      list-style: none;
      margin-top: 60px;
      padding-left:0;
      li{
        display: inline-block;
        margin: 0 20px;
        a{
          color: #0296a6;
          letter-spacing: 0.5px;
          @include transition(all, 0.15s, ease-in);
          &:hover{
            color: #333;
            text-decoration: none;
          }
        }
      }
    }
    &.split{
      ul{
        margin-top: 0;
        li{
          display: block;
          font-size: 1.2em;
          font-weight: 300;
          line-height: 1.7;
          margin-bottom: 10px;
          text-align: left;
          list-style-position: inside;
          text-indent: -1em;
          padding-left: 15px;
          @media all and (max-width:1024px){
            padding-left: 30px;
          }
          @media all and (max-width:991px){
            font-size: 1em;
          }

          &:last-of-type{
            margin-bottom: 0;
          }
          &:before{
            content: '';
            width: 20px;
            height: 15px;
            //background: url('../img/home/treatments/treatment2-arrow.png') no-repeat 0 100%;
            background: url('../img/tick.png');
            background-repeat: no-repeat;
            display: inline-block;
            background-size: cover;
            position: relative;
            z-index: 1;
            top: 0;
            left: -15px;
          }
        }
      }
    }
    &.review{
      background: #0796a6;
      .inner{
        .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
          width:7px;
          background: #fff;
        }
      }
      p{
        color: #fff;
      }
      img{
        width: 50px;
        margin-bottom: 35px;
      }
      .inner{
        padding: 0 px;
      }
    }
  }
  .img{
    padding:0;
  }
  .titlesec{
    margin-top: 0;
  }
  .video-outer{
    height: 560px;
    background-color: red;
    background-position: 50% 50%!important;
    background-size: cover!important;
    position: relative;
    .video-inner{
      position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        a.btn{
          margin: 0;
        }
    }
  }
  .row{
    &:nth-child(2n){
      .split{
        &:first-of-type{
          float: right!important;
        }
      }
    }
  }
}

@media all and (max-width: 1200px){
.treatment-process{
    .info{
      padding: 0 15px;
      ul{
        margin-top: 30px;
      }
    }
  }
}


@media all and (max-width: 1024px){
.treatment-process{
  padding: 0;
    .titlesec{
        margin-top: 0;
      }
    .video-outer{
      height: 560px;
    }
    .info{
      height: 560px;
      .inner{
        padding: 0 15px;
      }
      ul{
        li{
          margin: 0 5px;
          a{
            font-size: 0.9em;
          }
        }
      }
      &.review{
        .inner{
          padding: 0 15px;
          img{
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 768px){
.treatment-process{
  padding-bottom:0;
    .video-outer{
      margin-top: 0;
    }
    .info{
      padding-top: 0;
      ul{
        li{
          margin: 0 5px;
          a{
            font-size: 1em;
          }
        }
      }
    }
  }
}

@media all and (max-width: 767px){
.treatment-process{
    .info{
      padding-top: 0;
      height: auto;
      padding: 30px 15px;
      display: block!important;
      .inner{
        position: relative;
        left: 0;
        top: 0;
        transform: none;
        max-height: 99999px;
      }
      &.review{
        margin-top: 0;
        padding: 30px 15px;
      }
    }
    padding-bottom:0;
  }
}


@media all and (max-width: 650px){
.treatment-process{
    .video-outer{
      height: 275px;
      margin-bottom: 0;
    }
    .info{
      ul{
        li{
          margin: 5px 15px;
          display: block;
        }
      }
    }
  }
}

.ba-section{
  padding: 85px 15px;
  background: #008ea1;
  text-align: center;
  clear: both;
  .titlesec{
    margin-bottom: 58px;
    @media all and (max-width: 575px){
      margin-bottom: 40px;
    }
    h2{
      color: #fff;
      &:after{
        background: #fff;
      }
    }
  }
  .baSlider{
    .ba-img{
      width: 50%;
      float: left;
      padding: 0 15px;
      @media all and (max-width: 575px){
        padding: 0 5px;
      }
      img{
        width: 100%;
        border: 5px solid #fff;
      }
      span{
        display: block;
        margin-top: 10px;
        margin-bottom: 1.16rem;
        font-size: 1em;
        font-weight: 300;
        color: #fff;
        line-height: 1.7;
      }
    }
    p{
      color: #fff;
      clear: both;
    }
    .slick-dots {
      display: block;
      margin: 0;
      padding: 0;
      list-style-type: none;
      text-align: center;
      margin-top: 24px;
      @media all and (max-width: 575px){
        margin-top: 0;
      }
        li {
          margin: 0 10px;
          display: inline-block;
        }
        button {
          display: block;
          width: 8px;
          height: 8px;
          padding: 0;
          border: none;
          border-radius: 100%;
          background-color: #fff;
          text-indent: -9999px;
          &:hover{
            background-color: #13325a;
          }
        }
        li.slick-active button {
          background-color: #13325a;
        }
    }
  }
}

@media all and (max-width: 1024px){
  .ba-section{
    padding: 30px 0;
  }
}

@media all and (max-width: 768px){
  .ba-section{
    padding: 30px 0;
  }
}




.faq-process{
  padding: 85px 15px;
  background: #efeeee;
  text-align: center;
  clear: both;
  .titlesec{
    margin-bottom: 58px;
  }
    .btn-link{
      width: 100%;
      display: block;
      background: #fff;
      border: none;
      padding: 20px 30px;
      font-size: 1.2em;
      font-weight: 300;
      color: #444;
      margin: 10px 0;
      text-align: left;
      @include transition(all, 0.25s, ease-in);
      position: relative;
      span.fa{
        @include transition(all, 0.25s, ease-in);
        position: absolute;
        right: 30px;
        font-size: 1.5em;
        top: 50%;
        margin-top: -13px;
      }
      &:hover, &:focus{
        text-decoration: none;
        background: #41c3d5;
        color: #fff;
        span.fa{
          color: #fff;
        }
      }
      &.active{
        span.fa{
          transform: rotate(-90deg);
          -webkit-transform: rotate(-90deg);
          -moz-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
        }
      }
    }
      .inner{
        padding: 10px 30px;
        text-align: left;
      }
  .collapse{
    display: none;
    &.show{
      display: block;
    }
  }
}

@media all and (max-width: 1024px){
  .faq-process{
    padding: 30px 0;
  }
}

@media all and (max-width: 768px){
  .faq-process{
    padding: 30px 15px;
    .btn-link{
      padding: 15px 20px;
      span.fa{
        right: 15px;
      }
    }
  }
}

@media all and (max-width: 475px){
  .faq-process{
    .btn-link{
      padding: 15px 40px 15px 20px;
    }
  }
}


  /* ==========================================================================
   Overview
   ========================================================================== */

body.page-template-template-treatmentoverivewpage{
  .info-sec{
    padding: 45px 15px 25px 15px;
  }
}


.overview-sec{
  padding: 0 15px 35px 15px;
  .overview{
    text-align: center;
    margin-bottom: 40px;
    .image-sec{
      height: 275px;
      background-size: cover!important;
      background-position: 50% 50%!important;
      position: relative;
      margin-bottom: 20px;
      overflow: hidden;
      @include transition(all, 0.25s, ease-in-out);
      h2{
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: #fff;
        text-align: center;
        font-weight: 300;
        text-transform: uppercase;
      }
      &:hover{
         filter: brightness(75%);
      }
    }
    a.btn{
      margin-top: 10px;
    }
  }
}

@media all and (max-width: 1024px){
body.page-template-template-treatmentoverivewpage{
  .info-sec{
      padding: 30px 15px 10px;
  }
}
.overview-sec{
  padding: 0 15px 0 15px;
    .overview{
      .image-sec{
        h2{font-size: 1.6em;}
      }
    }
  }
}

@media all and (max-width: 768px){
.overview-sec{
    .overview{
      margin-bottom: 30px;
    }
  }
}

@media all and (max-width: 767px){
.overview-sec{
  padding:0;
  }
}


/* ==========================================================================
   Team
   ========================================================================== */
.modal-backdrop{
  z-index: 9999!important;
}

.modal{
  z-index: 9999999!important;
}

.teamPage-container{
  clear: left;
  padding: 0 15px 35px 15px;
  width: 100%;
  overflow: hidden;
  @media all and (max-width: 1024px){
    padding: 0 15px;
  }
  .container{
    padding: 0 15px;
  }
  .team-member{
      position: relative;
      margin-bottom: 40px;
      padding: 0 15px;





      &:nth-child(3n){
        clear: right;
        margin-right: 0;
      }
      &:nth-child(3n+1){
        clear: left;
      }
      @media all and (max-width: 1050px){
        margin-bottom: 25px;
      }

a.open-link{
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
}

    .imageouter{
      background: #999;
      padding: 0;
      .imageinner{
      background: #fff;
      padding: 0;
      text-align: center;
      overflow: hidden;
      position: relative;
      .info-overlay{
        position: relative;
        left: 0;
        bottom: 0;
        background: #13325a;
        width: auto;
        z-index: 9;
        padding: 10px 20px;
        h2 {
          color: #fff;
          font-size: 1.2em;
          margin-bottom: 0;
          margin-top: 0;
          display: block;
          font-weight: 400;
          @media all and (max-width: 1050px){
            font-size: 1.2em;
          }
        }// h2
        p{
          color: #fff;
          margin-bottom: 0;
          font-size: 1.1em;
        }
        @media all and (max-width: 1200px){
          left: 0;
          bottom: 0;
          width: 100%;
        }
      }
      img{
        @include transition(all, 0.25s, ease-in-out);
      }
      &:hover{
        img{
          transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -ms-transform: scale(1.1);
        }
      }

      }
    }

      img{
        width: 100%;
      }



      @media all and (max-width: 500px){
        .imageouter{
          width: 100%;
          padding: 0;
        }
        footer{
        padding: 0 !important;
        width: 100%;
        margin-top: 20px !important;
        }
      }


    @media all and (max-width: 550px){
      width: 100%;
      height: auto;
      margin-bottom: 20px;
      margin-right: 0!important;
      margin-left: 0!important;
    }

       &:hover{
          footer{
            p{
                &::after{
                  position: absolute;
                  left:50%;
                  bottom: -5px;
                  width: 50px;
                  height: 1px;
                  background: #fff;
                  z-index: 99999;
                  content: '';
                  margin-left: -25px;
                }
            }
          }
        }
  }

  .modal-dialog{
    max-width: 1100px!important;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    padding: 0 15px;
    -webkit-transform: translate(-50%, -50%)!important;
    -ms-transform: translate(-50%, -50%)!important;
    transform: translate(-50%, -50%)!important;
    .modal-content{
      padding: 0 25px 30px 15px;
      aside{
        padding: 25px 15px 5px 30px;
        float: left;
        width: 33.3333%;
        display: inline-block;
        img{
          border: 4px solid #13325a;
            width: 100%;
        }
      }
      article{

        padding: 25px 15px;
        padding-bottom: 5px;
        float: left;
        width: 66.6666%;
        display: inline-block;
        .textarea{
          max-height: 650px;
          overflow-y: scroll;
          .header{
            margin-bottom: 10px;
            h2{
              position: relative;
              color: #13325a;
              font-size: 1.8em;
              margin-bottom: 5px;
            }
            p.teamdesc{
              margin-bottom: 0;
              font-weight: 700;
            }
          }
          @media all and (max-width: 1024px){
            max-height: 450px;
          }
          @media all and (max-width: 575px){
            max-height: 320px;
          }
        }
      }
      @media all and (max-width: 650px){
        article{
          width: 100%;
        }
        aside{
          display: none;
        }
      }
    }
    @media all and (max-width: 575px){
      margin-left: 0;
    }
  }



}

body.page-template-template-team{


.popupContainer {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999999999999999999999999;
  display: none;

  &:before {
    background: #cccccc none repeat scroll 0 0;
    opacity: 0.89;
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
  }

  .popupInner {
    //background: rgba(22, 143, 195, 0.95) url(../img/popUpFormBG.jpg) no-repeat;
    background: $brandBlue;
    background-size: cover;
    max-width: 900px;
    width: 100%;
    height: auto;
    //max-height: 462px;
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    padding: 5px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    @media all and (max-width: 768px){
      height: auto;
    }

    .innerinner{
      background-color: rgba(255, 255, 255, 1);
      padding: 35px;
      float: left;
    @media all and (max-width: 650px){
      max-height: 400px;
      overflow-y: scroll;
    }
    a.close {
      background-image: url('../img/close.png')!important;
      background-size: cover;
      background-repeat: no-repeat;
      color: #fff;
      display: block;
      width: 20px;
      height: 19px;
      position: absolute;
      right: 30px;
      text-align: center;
      top: 30px;
      z-index: 999999;
      opacity: 1;
      @media all and (max-width: 768px){
        right: 20px;
        top: 28px;
        background-image: url('../img/close.png')!important;
        background-repeat: no-repeat !important;
        width: 20px;
        height: 19px;
        background-size: cover;
      }
    }// a.close
    }
    @media all and (max-width: 600px){
      padding: 10px;
    }




    hr{
      width: 90%;
      @media all and (max-width: 768px){
        width: 100%;
      }
    }


    &.teampage-container {
      max-width: 70%;
      @media all and (max-width: 768px){
        max-width: 95%;
      }

      article .textarea {
        max-height: 650px;
        padding-right: 10px;
        overflow-y: scroll;
        @media all and (max-width: 768px){
          padding-right: 0;
          max-height: 500px;
        }
        @media all and (max-width: 350px){
          max-height: 400px;
        }
      }
    }// &.teampage-container




      aside {
        padding-top: 20px;
        img {
          margin-left: -2%;
          max-width: 100%;
          margin-bottom: 20px;
        }


      }// aside

      article .textarea {

        overflow:hidden;
        max-height: 400px;
        text-align: left;

        @media all and (max-width: 600px){
          padding-left: 0;
        }
        a{
          color: #666;
           text-decoration: none;
          &:hover{
            text-decoration: underline;
          }
        }
        .header {
          margin-bottom: 10px;
          padding: 20px 0 0 0;

        h2 {
          color: $brandBlue;
          font-size: 2em;
          margin-top: 0;
          margin-bottom: 15px;
                @media all and (max-width: 768px){
                  font-size: 1.2em;
                }
        }// h2

        p{
          &.teamdesc{
            margin-bottom: 0;
          }
        }



        }// header

        @media all and (max-width: 600px){
          padding-top: 0px;
          overflow-y: scroll;
        }

      }// article
  }// .popupInner
}// .popupContainer





@media all and(max-width:980px) {
  .popupContainer .popupInner {
    width: 100%;
  }
}

}

@media all and(max-width: 600px) {
  body.page-template-template-team .popupContainer .popupInner .innerinner {
   padding: 35px 15px;
  }
}



/* ==========================================================================
   Template-reviews.php
   ========================================================================== */

.reviews-outer {
  background-color:#f2f2f2;
  overflow: hidden;


  ul.leave-reviews{
    list-style: none;
        text-align: center;
        margin-top: 50px;
        display: block;
        width: 100%;
        padding: 0;
        @media all and (max-width: 1024px){
          margin-top: 30px;
          margin-bottom: 0;

        }
    li{
      display: inline-block;
      margin: 0 20px;
        img{
          width: auto;
          height: 90px;
          @include transition(all, 0.25s, ease-in-out);
        }
      }
      @media all and (max-width: 650px){
        margin-top: 25px;
        li{
          margin: 0 10px;
          img{
            height: 70px;
          }
        }
      }
      @media all and (max-width: 450px){
        li{
          margin: 0 5px;
          img{
            height: 50px;
          }
        }
      }
    }
      h5{
        margin: 0;
        font-size: 1.2em;
        line-height: 1.8em;
        letter-spacing: 0.5px;
        margin-bottom: 25px;
        margin-top: 25px;
        font-weight: 600;
        text-align: center;
      }

      h4{
        font-size: 1.2em;
        font-weight: 600;
        letter-spacing: 0.3px;
        line-height: 1.8em;
        margin-bottom: 30px;
        text-align: center;
        a{
          color: $brandBlue;
          text-decoration: underline;
            @include transition(all, 0.25s, ease-in-out);
          &:hover, &:focus{
            color: #333;

          }
        }
      }





  ul.reviewlogos{
    list-style: none;
    text-align: center;
    width: 80%;
    margin: 30px auto 0 auto;
    @media all and (max-width: 1000px){
      width: 100%;
    }
    li{
      display: inline-block;
      width: 25%;
        @media all and (max-width: 1300px){
          width: 32%;
        }
      img{
        width: 200px;
        @media all and (max-width: 768px){
          width: 150px;
        }
        @media all and (max-width: 500px){
          width: 100px;
        }
        @media all and (max-width: 340px){
          width: 80px;
        }

      }
    }
  }
}

.reviews {
  padding: 50px 0;
  position: relative;
  margin:0!important;
  @media all and (max-width: 1024px){
    padding: 35px 0 10px 0;
  }

    .reviewinner {
      background: #fff;
      text-align: left;
      float: left;
      padding:0;
      border: 0px;
      padding: 40px 30px;
      margin-bottom: 25px;


      blockquote {
        padding: 0;
        border: 0;
      }

      img{
        width: 80px;
        display: block;
      }
      span.name {
        display: block;
        width: 100%;
        color: #045272;
        font-weight: bold;
        padding-bottom: 5px;
        text-transform: uppercase;
      }
      span.date {
        display: block;
        width: 100%;
        font-weight: 400;
        padding-bottom: 20px;
      }
      span.source {
        display: block;
        width: 100%;
        font-weight: 400;
        padding-top: 15px;
      }
      &.last {
        margin-right:0;
      }
    }
}




@media screen and ( max-width: 768px ){
  .reviews{
    padding: 25px 0!important;
    top:0;
    .review-box {
        width:48%;
        margin:0 1% 10px 1%;
      }
  }
}

@media screen and ( max-width: 600px ){
  .reviews{
    .review-box {
      width:48%;
      }
  }
}


@media screen and ( max-width: 550px ){
  .reviews{
    padding: 25px 15px!important;
    .review-box {
       width:95%;
       margin:0 0% 10px 0;
       padding: 0;
      }
  }
}

@media screen and ( max-width: 400px ){
  .reviews{
    .review-box {
       width:90%;
      }
  }
}


/* ==========================================================================
   Template-price-list.php
   ========================================================================== */



section.priceList-container {
  padding: 0 15px 0 15px;
  @media all and (max-width: 1024px){
    padding: 0 15px 0 15px;
  }
  .top{
    text-align: center;
  }

      h5{
        margin: 0;
        font-size: 1.2em;
        line-height: 1.8em;
        letter-spacing: 0.5px;
        margin-bottom: 25px;
        margin-top: 25px;
        font-weight: 600;
      }

      h4{
        font-size: 1.2em;
        font-weight: 600;
        letter-spacing: 0.3px;
        line-height: 1.8em;
        margin-bottom: 30px;
        a{
          color: $brandBlue;
          text-decoration: underline;
  @include transition(all, 0.25s, ease-in-out);
          &:hover, &:focus{
            color: #333;

          }
        }
      }





  .container{
    margin-top: 0;
    &.top{
      margin-top: 0;
    }
  }
  @media all and (max-width: 768px){
    padding: 0px;
    .container{
      padding: 0;
      margin-top: 0px;
    }
  }
  .pricecont{
    padding-left: 0;
    @media all and (max-width: 768px){
      padding-right: 0;
    }
    @media all and (max-width: 575px){
      padding: 0 15px;
    }
  }
  .priceright{
    text-align: center;
    padding: 0 35px;
    @media all and (max-width: 1024px){
      padding: 0;
    }
    @media all and (max-width: 768px){
      width: 100%;
    }
    @media all and (max-width: 575px){
      padding: 0 15px;
    }


        img.priceimage{
          width: 100%;
          margin-top: 10px;
          margin-bottom: 30px;
          display: block;
          float: left;
          padding: 0;

          @media all and (max-width: 768px){
            padding: 0;
            margin-top: 10px;
            margin-bottom: 15px;
          }
          @media all and (max-width: 600px){
            margin-top: 10px;
          }
        }





  }
    h3{
        margin: 0;
        margin-bottom: 0;
        margin-top: 15px;
        font-size: 1.6em;
        line-height: 1.8;
        color: #13325a;
      @media all and (max-width: 600px){
        margin-top: 0px;
      }
    }


  table.prices-table {
    border-top: 1px solid #E8E8E8;
    border-left: 1px solid #E8E8E8;
    border-right: 1px solid #E8E8E8;
    width: 100%;
    margin-top: 10px !important;
    margin-bottom: 15px !important;
    margin: 0 auto;
        letter-spacing: 0.2px;



    thead tr {
      background: #333; color: #fff; font-size: 1.01em;

    }

    td {
      border: 1px solid #E8E8E8;
    }

    td.leftCol {
      width: 70%;
      @media all and (max-width: 600px){
        width: 65%;
      }
      @media all and (max-width: 500px){
        width: 70%;
      }
      @media all and (max-width: 400px){
        width: 80%;
      }
    }
    td.rightCol {
      width: 30%;
      @media all and (max-width: 600px){
        width: 35%;
      }
      @media all and (max-width: 500px){
        width: 30%;
      }
      @media all and (max-width: 400px){
        width: 20%;
      }
    }
    td.Col4 { width: 25%; }


    tr {
      border-bottom: 1px solid #E8E8E8; background: #fff;
      &:hover{
        background: #13325a;
        @include transition(all, 0.25s, ease-in-out);
        color: #fff;
      }

      &:nth-child(even) {
        background: #f1f1f1;
      &:hover{
        background: #13325a;
          @include transition(all, 0.25s, ease-in-out);
        color: #fff;
      }
      }

      td {
        padding: 9px 15px;
        margin-top: 0;
        margin-bottom: 1.16rem;
        font-size: 1.2em;
        font-weight: 300;
        line-height: 1.7;
      }
    }

    @media all and(max-width:767px) {
      max-width: 100%;
    }
    &.top{
      tr{
        background: #13325a;
        color: #fff;
        font-size: 1em;
      }
    }
  }// table.prices-table

}// section.priceList-container





@media all and(max-width:768px) {
  section.priceList-container {
    padding: 0 15px 20px 15px;

    .col-xs-12 {
      margin-top: 0;
    }
    h3 {
      margin-bottom: 0;
    }
    table.prices-table {
      margin-top: 5px!important;
      margin-bottom: 13px !important;
    }
  }
}

/* ==========================================================================
 Contact
 ========================================================================== */

 .map-section{
   display: block;
   width: 100%;
   margin-top: 0;
   margin-bottom: 25px;
   float: left;
   #map-canvas{
     height: 450px;
     width: 100%;
     @media all and (max-width: 768px){
       height: 400px;
     }
   }
     @media all and (max-width: 768px){
       display: none;
     }
     &.contact{
       position: relative;
     .map-directions {
       text-align:center;
       position:absolute;
       z-index:9;
       background-color:#fff;
       padding:20px;
       right: 50px;
       top:80px;
       width:480px;
       box-shadow:0 2px 7px #999;
       z-index: 1;
       p {
         margin-bottom:0;
       }
       p.title {
         text-transform:uppercase;
         color:#555;
         padding-bottom:10px;
         font-size:1.4em;
       }
       strong {
         color:#13325a;
         font-weight: 700;
       }
       /*  Directions grey box  */
       .address {
         color:$brandBlue;
         background-color:#f7f7f7;
         float: left;
         margin-bottom: 10px;
         width:100%;
         padding: 20px;
         a {
           text-decoration: underline; color: #444; font-weight: 300; letter-spacing: 1px; font-size:14px; @include transition(all, 0.25s, ease-in-out);
           &:hover { text-decoration: none; }
         }
       }// end address
       input#saddr {
         border: 1px solid #999;
         height: 36px;
         margin-top: 10px;
         padding: 8px;
         width: 67%;
       }
       input#button {
         background-color:#13325a;
         color: #fff;
         border:0;
         border:1px solid #13325a;
         width:31%;
         padding: 5px;
         margin-top: 10px;
         float:right;
         text-transform:uppercase;
         height: 36px;
         font-weight: 400;
         font-size: 12px;
         letter-spacing: 1px;
         &:hover {
           background-color:#333;
           border:1px solid #333;
           cursor:pointer;
           color:#fff;
           @include transition(all, 0.25s, ease-in-out);
         }
       }
       @media all and (max-width: 1024px){
         display: none;
       }
     }//end of the box inside the map
     }
 }

.contact-sec{
  text-align: left;
  clear: both;
  &.form{
    text-align: center;
    background: #f4f4f4;
    article,aside{
      padding: 0 30px;
      @media all and (max-width: 1024px){
        padding: 0 15px;
      }
    }
    @media all and (max-width: 768px){
      padding: 25px 15px 25px 15px;
    }
  }
  .contact-info{
    p{
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }
h1{
  position: relative;
  color: #045272;
  font-size: 1.8em;
  margin-bottom: 20px;
}
ul{
  list-style: none;
  padding-left:0;
  float: left;
  margin-top: 8px;
  li{
    display: block;
    font-weight: 300;
    border: 1px solid #fff;
    float: left;
    width: 100%;
      background: #fff;
    span{
      width: 50%;
      float: left;
      display: block;
      padding: 10px;
      &.day{
        border-right: 1px solid #fff;
      }
    }
    &:nth-child(even){
      background: #f4f4f4;
      span{
        &.day{
          border-right: 1px solid #fff;
        }
      }
    }
    &.active{
      background: #13325a;
      color: #fff;
    }
  }
}


  img{
    width: 100%;
    border: 5px solid #13325a;
  }

  p.small{
    font-weight: 300;
    font-size: 0.8em!important;
    margin-bottom: 5px;
  }

  p.compulsory{
    text-align: right;
    font-size: 0.8em;
  }

  form{
    margin-top: 20px;
    p{
      margin-bottom: 0;
    }
    .btn{
      margin-top: 25px;
    }
    br{
      display: none;
    }
    .col-lg-4{
      padding: 0 20px!important;
    }
    .col-lg-8{
      padding: 0!important;
    }

    .col-lg-6, .col-lg-12 {
      padding: 0 20px!important;
      float: left;
    }




    input.wpcf7-text, select, textarea {
      width: 100%;
      background: #fff;
      color: #13325a;
      display: block;
      border: none;
      font-weight: 400;
      font-size: 15.5px;
      letter-spacing: 1px;
      padding: 10px 17px;
        -webkit-appearance: none;
    -webkit-border-radius: 0px;
    text-transform: uppercase;

      &::placeholder {
        font-size: 1em;
        color: #13325a;
        opacity: 1;
      }
      &.wpcf7-not-valid{
        border: 1px solid red;
      }
      @media all and (max-width: 768px){
        font-size: 0.9em;
        padding: 10px 17px;
      }
    }

    input.wpcf7-text, select {
      margin-bottom: 20px;
    }
    select {
      cursor: pointer;

      &:after {
        font-family:'FontAwesome';
        content:"\f107";
      }

    }

    textarea {
      height: 128px;
      max-height: 128px;
      min-height: 128px;
    }

    @media all and (max-width: 1024px){
      .btn{
        margin-top: 15px!important;
      }
    }



         input[name=tel-982] {
          background: #fff url(../img/tel.png) no-repeat;
          background-size: 16px 16px;
          background-position: 10px 15px;
          padding-left: 40px !important;
        }

         select[name=menu-161] {
          background: url(../img/interested.png) 10px 15px no-repeat, url(../img/drop-down-arrow.png) 95% 15px no-repeat !important;
          background-size: 16px 16px !important;
          background-position: 10px 15px;
          padding-left: 40px !important;
          background-color: #fff!important;
          margin-bottom: 25px;
        }

         select[name=menu-456] {
          background: url(../img/pref-contact.png) 10px 10px no-repeat, url(../img/drop-down-arrow.png) 95% 10px no-repeat !important;
          background-size: 16px 16px !important;
          background-position: 10px 15px;
          padding-left: 40px !important;
          background-color: #fff!important;
        }


        textarea[name=textarea-343] {
          background: #fff url(../img/message.png) no-repeat;
          background-size: 16px 16px;
          background-position: 10px 15px;
          padding-left: 40px !important;
        }

        textarea[name=textarea-344] {
          background: #fff url(../img/message.png) no-repeat;
          background-size: 16px 16px;
          background-position: 10px 15px;
          padding-left: 40px !important;
        }

        input[name=full-name] {
          background: #fff url(../img/name.png) no-repeat;
          background-size: 16px 16px;
          background-position: 10px 15px;
          padding-left: 40px !important;
        }

        input[name=email-829] {
          background: #fff url(../img/email.png) no-repeat;
          background-size: 16px 16px;
          background-position: 10px 15px;
          padding-left: 40px !important;
        }

        .custom-select {
          position: relative;
          background: url(../img/interested.png) 10px 16px no-repeat, url(../img/drop-down-arrow.png) 97% 15px no-repeat !important;
          background-size: 16px 16px !important;
          background-position: 10px 15px;
          padding-left: 40px !important;
          background-color: #fff!important;
          margin-bottom: 20px;
          border-radius: 0;
          border: none;
          height: 47px;
          color: #13325a;
          border: none;
          font-weight: 400;
          font-size: 1em;
          letter-spacing: 1px;
          text-transform: uppercase;
          padding-top: 8px;
          padding-right: 0;
          select {
            display: none; /*hide original SELECT element:*/
          }
        }
        .select-selected {
          text-align: left;
          margin: 4px 0;
          padding: 0;
  
        }
        /*point the arrow upwards when the select box is open (active):*/
        .select-selected.select-arrow-active:after {
          //border-color: transparent transparent #fff transparent;
          top: 12px;
          transform: rotate(180deg);
        }
        /*style the items (options), including the selected item:*/
        .select-items div,.select-selected {
          cursor: pointer;
          user-select: none;
        }
        /*style items (options):*/
        .select-items {
          position: absolute;
          background-color: #fff;
          top: 100%;
          left: 0;
          right: 0;
          z-index: 99;
          text-align: left;
          padding: 10px 15px;
          div {
            border-top: 1px solid #FEFEFE;
          }
        }
        /*hide the items when the select box is closed:*/
        .select-hide {
          display: none;
        }
        .select-items div:hover, .same-as-selected {
          color: #008ea1;
        }
  
  


    label {
      margin-top: 15px;
      font-weight: 300;
      letter-spacing: 0.5px;
      color: #333;
      text-align: center;
      display: block;
       @media all and (max-width: 768px){
        text-align: center;
       }
      @media all and (max-width: 575px){
        display: block;
      }
    }

  a.policy-link{
    display: inline-block;
    color:#fff;
    font-size: 0.8em;
    font-weight: 300;
    float: left;
    margin-left: 30px;
    margin-top: 4px;
    &:hover{
      color: #333;
      text-decoration: none;
    }
    @media all and (max-width: 768px){
      float: none;
      display: block;
      margin-left: 0;
      margin-top: 10px;
    }
  }



    .radio-729 {
  width: auto;
  text-align: center;
  display: block;
      input {
        cursor: pointer;
        margin-left: 5px;
        position: relative;
        top: -3px;
        @media all and (max-width: 1024px){
          top: 1px;
        }
      }
      span {
        position: relative;
        left: 2px;
        font-size: 1em;
        text-transform: uppercase;
        top: 0px;
        color: #333;
        font-weight: 300;
        letter-spacing: 1px;
          &.first{
            margin-left: 0!important;
          }
      }
      @media all and (max-width: 768px){
        float: none;
      }
      @media all and (max-width: 575px){
        .wpcf7-list-item{
          margin: 0;
          margin: 0 10px!important;
        }
      }
    }

    .g-recaptcha{
        text-align: center;
        position: relative;
        left: 50%;
        margin-left: -152px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

  span.wpcf7-not-valid-tip{
    display: none;
  }

  .button{
    padding: 10px 40px;
      border-image-outset: 0;
      border-image-repeat: stretch;
      border-image-slice: 100%;
      border-image-source: none;
      border-image-width: 1;
      border-image: linear-gradient(90deg, #888888, #e7e7e7, #888888) 1 !important;
      &:hover{
        border: 2px solid #888!important;
      }
  }


  .wpcf7-validation-errors{
    margin: 0;
    background: red;
    border: 2px solid red;
    color: #fff;
    padding: 10px 20px;
    font-weight: 300;
  }

    }// .button




  .ajax-loader{
    display: block!important;
    clear: both!important;
  }

  @media all and (max-width: 768px){
    .col-xl-3{
      display: none;
      &:first-of-type{
        display: block;
        text-align: center;
        width: 100%;
        max-width: 100%;
        flex: none;
      }
    }
    article{
        width: 100%;
        max-width: 100%;
        flex: none;
        padding: 0;
    }
    aside{
        width: 100%;
        max-width: 100%;
        flex: none;
        padding: 0;
        .contact-info{
          margin-top: 20px;
        }
    }
  }

}

body.page-id-102{
  header.main{
    nav.main{
      ul.primary-nav{
        li#menu-item-125{
          a.parent{
            color:#13325a;
            @media all and (max-width:991px){
              color:#fff;
            }
            &:after{
              display: none;
            }
            &:hover{
              color: #41c3d5;
              &:after{
                display: initial;
              }
            }
          }
        }
      }
    }
  }
}

body.page-template-template-policy{
  .info-sec{
    text-align: left;
    .titlesec{
      h1{
        margin-bottom: 25px;
        &:after{
          display: none!important;
        }
      }
    }
    h2{
      position: relative;
      color: #045272;
      font-size: 1.8em;
      margin-bottom: 20px;
    }
    ul{
      padding: 0;
      padding-left: 20px;
      li{
        margin-top: 0;
            margin-bottom: 1.16rem;
            font-size: 1.2em;
            font-weight: 300;
            line-height: 1.7;
      }
    }
  }
}



/* ==========================================================================
   Blog
   ========================================================================== */

section.blogTitle-container {
  background: #13325a;
  padding: 25px 15px 15px 15px;
  text-align: center;
  color: #fff;

  h1{
    position: relative;
    color: #fff;
    font-size: 2.4em;
    margin-bottom: 48px;
    &::after{
      height: 5px;
      width: 64px;
      background: #fff;
      position: absolute;
      left: 50%;
      bottom: -22px;
      margin-left: -32px;
      z-index: 9;
      content: '';
    }
    @media all and (max-width: 768px){
      font-size: 1.6em;
      margin-bottom: 30px;
    }
  }

  p{
    @media all and (max-width: 768px){
      margin-bottom: 0;
    }
  }

  form {
    margin-top: 30px;
    display: block;

    .email {
      border: none;
      font-style: italic;
      padding: 5px 12px;
      width: 260px;
      height: 35px;
      color: #000;
    }
    .bttn {
      background: none;
      color: #fff;
      border: 1px solid #fff;
      @include border-radius(20px);
      font-weight: 600;
      letter-spacing: 1px;
      padding: 7px 0;
      max-width: 150px;
      margin-left: 20px;
      width: 100%;
      display: inline-block;
      @include transition(all, 0.15s, ease-in-out);
      text-decoration: none !important;

      &:hover {
        background: #fff;
        color: #333;
      }
      &:after {
        display: none;
      }
    }

    .mc4wp-response {
      margin-top: 20px;
    }
  }// form
}// section.blogTitle-container

@media all and(max-width: 991px) {
  section.blogTitle-container {
    padding: 20px 15px 10px 15px;
    //padding-top: 120px;
  }
  section.blogTitle-container form .bttn {
    margin-left: 0 !important;
  }
  section.blogTitle-container form .email {
    margin-bottom: 15px;
  }
  
}

@media all and(max-width: 768px) {
  section.blogTitle-container {
    padding: 20px 15px 25px 15px;
    //padding-top: 120px;
  }
}


@media all and(max-width: 600px) {

  section.blogTitle-container form .bttn {
    margin-left: 0 !important;
  }
  section.blogTitle-container form .email {
    margin-bottom: 15px;
  }
}



.archive-title-holder {
  background: red;
  text-align: center;
  padding: 30px 0;
  @media all and(max-width:768px) {
    padding: 15px 0;
  }

  h1 {
    color: #fff;
  }
}


section.blogPost-container {
  width: 100%;
  height: 100%;
  padding: 70px 0;
  @media all and (max-width: 1024px){
    padding: 30px 15px 10px 15px;
  }


  .blog-post {
    margin-bottom: 25px;
    @media all and (max-width: 1024px){
      margin-bottom: 0;
    }


    &:nth-child(odd) {
      clear: left;
    }

    article {
      padding-bottom: 11px;
    }

    h2{
      position: relative;
      color: #045272;
      font-size: 1.4em;
      margin-bottom: 10px;
      a{
        color: #045272;
      }
      @media all and (max-width: 1024px){
        font-size: 1.4em;
      }
    }



    .date {
      background: $teal;
      padding: 3px 5px;
      color: #fff;
      font-size: 0.85em;
      margin-bottom: 15px;
      display: inline-block;
      display: none;
    }

    .author {
      margin-left: 20px;
      margin-left: 0;
      font-size: 0.93em;
    }

    a.img-holder {
      overflow:hidden;
      display: block;
        margin-top: 12px;
        margin-bottom: 14px;

      img {
        width: 100%;
        max-width: 100%;
        height: auto;

        @include transition(all, 0.25s, ease-in-out);
      }

      &:hover img {
         transform: scale(1.1);
      }
    }



    .more-link{
      background: rgba(255, 255, 255, 0.8);
      border: 2px solid $brandcolour01;
      text-transform: uppercase;
      color: #13325a;
      padding: 8px 20px 7px 20px;
      min-width: 190px;
      font-size: 0.85em;
      letter-spacing: 1px;
      border-radius:0;
      display: inline-block;
      text-decoration: none;
      @include transition(all, 0.25s, ease-in-out);
      cursor: pointer;
      font-weight: 500;
      margin: 0;
      margin-top: 20px;
      clear: both;
      display: block;
      max-width: 155px;
      text-align: center;
      i{
        display: none;
      }
      &:hover{
        background:$brandcolour01;
        color: #fff;
      }
    }

    .addtoany_content {
      /* clear: both; */
      /* margin: 16px 0; */
      float: right;
      margin-top: -40px;

      &:before {
        content: "Share with:";
        font-weight: 500;
        font-size: 0.96em;
        position: relative;
        bottom: -2px;
      }
    }

    .a2a_button_facebook {
      font-size: 31px;
    }

    .a2a_button_twitter {
      position: relative;
      top: 1px;
    }

    .addtoany_list.a2a_kit_size_32 a {
      padding: 0 2px !important;
    }

    .addtoany_list.a2a_kit_size_32 a:not(.addtoany_special_service)>span {
      height: 20px;
      width: 20px;
      background: none !important;
    }

    .a2a_svg.a2a_s__default.a2a_s_email {
      height: 21px !important;
      width: 23px !important;
    }

    .a2a_svg svg path {
      fill: #000 !important;
    }

  }//.blog-post


  nav.pagination {
    display: block;
    width: 100%;

    .page-numbers:first-child {
      border-left: 1px solid #AAAAAA;
    }

    .page-numbers {
      border-right: 1px solid #AAAAAA;
      /* padding: 1px 2px; */
      text-align: center;
      /* padding-right: 6px; */
      border-top: 1px solid #AAAAAA;
      border-bottom: 1px solid #AAAAAA;
      margin: 0;
      padding: 2px 7px;
      float: left;
      color: #333;
      text-decoration: none;
      @include transition(all, 0.25s, ease-in-out);

      &:hover {
        color: #fff;
        background: #333;
      }
    }
  }// nav.pagination



  .nav-content{
    margin-top: -30px;
    text-align: center;
    padding: 0 50px;
    @media all and (max-width: 1024px){
      margin-top: -35px;
      padding: 0 15px;
    }
    h3{
      margin-top: 40px;
          position: relative;
          color: #045272;
          font-size: 1.2em;
          margin-bottom: 10px;
      &.wtitle{
        @media all and (max-width: 575px){
          margin-top: 0;
        }
      }
    }

    ul{
      margin-bottom: 10px;
      padding: 0;
      li{
        list-style: none;
        background: transparent;
        text-align: center;
            > a:after {
              content: "\f0da";
              font-family: FontAwesome;
              margin-left: 10px;
            }
        a{
          color: #777;
          display: block;
          padding: 7px 0;
          text-decoration: none;
          background: 0;
          border-bottom: 1px solid #c1c1c1;
        @include transition(all, 0.25s, ease-in-out);
        &:hover{
          background: #333;
          color: #fff;
        }
        }
        &.selected{
          margin-top: 10px;
          > a {
            background: #13325a;
            color: #fff;
                &:after {
                  content: "\f0d7";
                  font-family: FontAwesome;
                  margin-left: 10px;
                }
          }
        }


      }
    }
    @media all and (max-width: 767px){
padding: 0 !important;
margin: 0 !important;
width: 100%;
    }
  }




  aside.main {
    text-align: center;
    padding-left: 60px;
    margin-top: 50px;

      div.widget {
        float: left;
        width: 100%;
        h3 {
          display: block;
          background: none;
          color: #000;
          display: block;
          font-weight: 800;
          padding: 7px 0;
          padding-bottom: 13px;
          margin-bottom: 0;
          text-align: center;
          border-bottom: 1px solid #C1C1C1;
          font-size: 1.36em;
        }

        ul li {
          list-style: none;
          border-bottom: 1px solid #C1C1C1;
          background: transparent;
          text-align: center;

          a {
            color: #666;
            display: block;
            font-size: 0.97em;
            padding: 7px 0;
            text-decoration: none;
            background: none;
            @include transition(all, 0.25s, ease-in-out);
            &:hover {
              background: #333;
              color: #fff;
            }
          }
        }//  ul li

        &.blog-list-archive {
          ul li {
            > a:after {
              content: "\f0da";
              font-family: FontAwesome;
              margin-left: 10px;
            }

            &.selected {
              > a {
                background: #333333;
                color: #fff;
                padding-left: 10px;

                &:after {
                  content: "\f0d7";
                  font-family: FontAwesome;
                  margin-left: 10px;
                }
              }
            }
            ul li {
              border: none;
              a {
                text-transform: none;

                &:after { display: none; }
              }
            }
          }
        }
        ul{
          &.archive-year{
            li{
              ul{
                display: none;
              }
              &.first{
                ul{
                  display: block;
                }
              }
            }
          }
        }
      }// End div.widget
  }// aside.main
}// section.blogPost-container

@media all and(max-width:991px){
  section.blogPost-container aside.main {
    padding-left: 15px;
        margin-top: -20px;
  }
}


@media all and(max-width:767px){
  section.blogPost-container .alm-listing li {
    text-align: center;
    aside {
      display: none;
    }

  }//section.blogPost-container .alm-listing li

  section.blogPost-container .alm-listing{
    .alm-reveal{
      float: left;
      width: 100%;
    }
    li{
      article{
        a.more-link-extra{
          display: inline;
        }
      }
    }
  }
}


/* ==========================================================================
 Blog Single
 ========================================================================== */

section.blog-article-content {

  padding: 0;
  @media all and (max-width: 1024px){
    padding: 0 15px;
  }

  .article-inner-section {
    margin-top: 70px;
    margin-bottom: 70px;
    @media all and (max-width: 1024px){
      margin-top: 35px;
      margin-bottom: 35px;
    }
      .header-titles{
        padding-bottom: 0.5em;

        p.date {
          font-weight: 300;
          background: $teal;
          display: inline-block;
          padding: 5px 15px;
          margin-bottom: 20px;
          color: #fff;
          display: none;
        }

        h1.title {
            position: relative;
            color: #045272;
            font-size: 1.8em;
            margin-bottom: 10px;
            a{
              color: #045272;
            }
          @media all and (max-width: 768px){
            font-size: 1.4em;
          }
          @media all and (max-width: 650px){
            font-size: 1.2em;
          }
        }




      }// .header-titles


      h2{
        color: #045272;
        font-size: 1.4em;
        @media all and (max-width: 768px){
          font-size: 1.2em;
        }
        @media all and (max-width: 650px){
          font-size: 1em;
        }
      }


      .img-container {
        float: right;
        max-width: 200px;
        margin-left: 20px;
        margin-bottom: 15px;
        img{
          max-width: 100%;
          height: auto;
        }
      }// .img-container




    .addtoany_content {
      /* clear: both; */
      /* margin: 16px 0; */
      float: right;
      margin-top: 34px;

      &:before {
        content: "Share with:";
        font-weight: 500;
        font-size: 0.96em;
        position: relative;
        bottom: -2px;
      }
    }


    .a2a_button_facebook {
      font-size: 31px;
    }

    .a2a_button_twitter {
      position: relative;
      top: 1px;
    }

    .addtoany_list.a2a_kit_size_32 a {
      padding: 0 2px !important;

      &:after {
        display: none !important;
      }
    }

    .addtoany_list.a2a_kit_size_32 a:not(.addtoany_special_service)>span {
      height: 20px;
      width: 20px;
      background: none !important;
    }

    .a2a_svg.a2a_s__default.a2a_s_email {
      height: 21px !important;
      width: 23px !important;
    }

    .a2a_svg svg path {
      fill: #000 !important;
    }



    .tags {
      margin-top: 50px;
      i {
        //margin-left: 17px;
      }
    }// .tags

    nav {
      display: block;
      border-top: 1px solid #AAAAAA;
      clear: both;
      padding-top: 16px;
      margin-top: 22px;
      span{
        color: #045272;
        margin-top: 0;
        margin-bottom: 1.16rem;
        font-size: 1.2em;
        font-weight: 300;
        line-height: 1.7;
        a{
          color: #045272;
          @include transition(all, 0.25s, ease-in-out);
          &:hover{
            text-decoration: none;
            color: #41c3d5;
          }
        }
      }

      .right {
        float: right;
      }
    }
    @media all and (max-width: 575px){
      padding:0;
    }
  }// .article-inner-section

  .nav-content{
    margin-top: 85px;
    text-align: center;
    padding: 0 50px;
    @media all and (max-width: 1024px){
      margin-top: 40px;
      padding: 0 15px;
    }
    h3{
    margin-top: 40px;
    position: relative;
    color: #045272;
    font-size: 1.2em;
    margin-bottom: 10px;

      &.wtitle{
        @media all and (max-width: 575px){
          margin-top: 0;
        }
      }
    }

    ul{
      margin-bottom: 10px;
      padding: 0;
      li{
        list-style: none;
        background: transparent;
        text-align: center;
            > a:after {
              content: "\f0da";
              font-family: FontAwesome;
              margin-left: 10px;
            }
        a{
          color: #777;
          display: block;
          font-size: 0.95em;
          padding: 7px 0;
          text-decoration: none;
          background: 0;
          border-bottom: 1px solid #c1c1c1;
        @include transition(all, 0.25s, ease-in-out);
        &:hover{
          background: #333;
          color: #fff;
        }
        }
        &.selected{
          margin-top: 10px;
          > a {
            background: #13325a;
            color: #fff;
                &:after {
                  content: "\f0d7";
                  font-family: FontAwesome;
                  margin-left: 10px;
                }
          }
        }


      }
    }
    @media all and (max-width: 991px){
      padding: 0 !important;
      margin: 0 !important;
      width: 100%;
    }
    @media all and (max-width: 575px){
      margin: 0 !important;
      margin-bottom: 25px!important;
    }
  }


}// section.blog-article-content

@media all and(max-width:1440px) {
  section.blog-article-content .article-inner-section .inner {
    padding: 8em 0px 8em 2em;
  }
}

@media all and(max-width:768px) {
  section.blog-article-content {
    .article-inner-section .inner {
      padding: 4em 0 4em 0;

      .img-container {
        display: none;
      }
    }// .article-inner-section .inner

    aside {
      float: none;
      clear: both;
    }

    aside .nav-content {
    margin: 0 auto;
    float: none;
    padding: 0;
    display: block;
    clear: both;
    display: table;
    height: 96%;
    margin-bottom: 10px;

      &:after {
        display: none;
      }
    }// aside .nav-content
  }// section.blog-article-content
}


@media all and(max-width:767px) {
  .single.single-post section.bannerSlider-container {
    padding-bottom: 0;
    .slider .slick-slide .banner-content h1 {
      margin-bottom: 0;
    }
  }
  section.blog-article-content .article-inner-section {
    margin-top: 34px;
  }
}


body.blog, body.single-post{
  @media all and (max-width: 1024px){
    footer.main{
      padding-top: 0;
    }
  }
}

/* ==========================================================================
 Blog Archive
 ========================================================================== */
.archive-title-holder {
  background: red;
  text-align: center;
  padding: 30px 0;
  h1{
    margin: 0;
    color: #ffffff;
  }
}

.mason{
  li{
    list-style: none;
    width: 100%;
    float: left;
    padding: 45px 0;
    border-bottom: 1px solid #ffffff;
    article {
      p.date {
        font-weight: 500;
        margin: 0 0 10px;
      }
      h3 {
        a {
          margin-top: 40px;
          position: relative;
          color: #045272;
          font-size: 1.2em;
          margin-bottom: 10px;
        }
      }






      .featured-img {
        position: relative;
        max-height: 340px;
        overflow: hidden;

      }// .featured-img

      a.more-link{
        display: none;
      }

      a.more-link-extra {
        display: table;
        margin-top: 33px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: red;
      }
    }// article

    aside {
      overflow: hidden;
      max-height: 251px;
      border-left: 4px solid #0092c1;
      border-left: 4px solid red;
      padding-left: 30px;

      img {
        position: relative;
      }
    }// aside

    &.no-img {
      padding-top: 45px;
      padding-bottom: 45px;
    }// &.no-img
  }
}


.homepopup{
  .modal-dialog{
      max-width: 1100px!important;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%)!important;
      -ms-transform: translate(-50%, -50%)!important;
      transform: translate(-50%, -50%)!important;
      .modal-content{
        .modal-body{
          .embed-responsive{
            margin-top: 40px
          }
        }
      }
  }
}

body.page-template-template-smile-gallery{
  scroll-behavior: smooth!important;
}

.ba-subnav{
  background: #eee;
  padding: 10px 15px;
  margin-bottom: 50px;
  @media all and (max-width: 1024px){
    margin-bottom: 30px;
  }
  ul{
    list-style: none;
    text-align: center;
    margin-bottom: 0;
    padding: 0;

    li{
      display: inline-block;
      margin: 0 10px;

      a{
        color: #133557;
        font-weight: 300;
        font-size: 1.2em;
        @include transition(all, 0.25s, ease-in-out);
        &:hover{
          color: #333;
          text-decoration: none;
        }
      }
    }
  }

}

.ba-sec{
  .container{
    margin-bottom: 50px;
    scroll-margin-top: 5rem;
    @media all and (max-width: 1024px){
      margin-bottom: 30px;
    }
  }
  h2{
    position: relative;
    color: #045272;
    font-size: 1.8em;
    margin-bottom: 30px;
    display: block;
    width: 100%;
    text-align: center;
    &::after{
      height: 5px;
      width: 64px;
      background: #0296a6;
      position: absolute;
      left: 50%;
      bottom: -22px;
      margin-left: -32px;
      z-index: 9;
      content: '';
    }
    @media all and (max-width: 768px){
      font-size: 1.6em;
      margin-bottom: 20px;
    }
  }
  span.title{
    display: block;
    text-align: center;
    margin-top: 5px;
    clear: both;
    font-weight: 300;
  }

  .ba-item{
    background: #eee;
    padding: 15px;
    margin-bottom: 0;
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    .split{
      width: 50%;
      float: left;
      position: relative;
      img{
        width: 100%;
        position: relative;

      }
      span{
        text-align: center;
        display: block;
        position: absolute;
        bottom: 5px;
        width: 100%;
        left: 0;
        color: #fff;
        text-transform: uppercase;

      }
    }
  }
}
