/* Border radius */
@mixin border-radius($radius: 5px){
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

/* Border radius Example */
#somediv {
  @include border-radius(20px);
}



/* Border radius custom sides */
@mixin border-radius-custom($topleft: 5px, $topright: 5px, $bottomleft: 5px, $bottomright: 5px){
    -webkit-border-radius: $topleft $topright $bottomright $bottomleft;
    -moz-border-radius: $topleft $topright $bottomright $bottomleft;
    border-radius: $topleft $topright $bottomright $bottomleft;
}

/* Border radius custom sides Example */
#somediv {
    @include border-radius-custom(20px, 20px, 0px, 0px);
}



/* Box shadow */
@mixin box-shadow($x: 0px, $y: 3px, $blur: 5px, $alpha: 0.5){
    -webkit-box-shadow: $x $y $blur rgba(0, 0, 0, $alpha);
    -moz-box-shadow: $x $y $blur rgba(0, 0, 0, $alpha);
    box-shadow: $x $y $blur rgba(0, 0, 0, $alpha);
}

/* Box shadow Examples */
#somediv {
    @include box-shadow(5px, 5px, 6px, 0.3);
}




/* Transitions */
@mixin transition($prop: all, $time: 0.3s, $ease: linear){
    -webkit-transition: $prop $time $ease;
    -moz-transition: $prop $time $ease;
    -o-transition: $prop $time $ease;
    -ms-transition: $prop $time $ease;
    transition: $prop $time $ease;
}

/* Transitions Example */
#somediv {
    @include transition(all, 0.5s, ease-in);
} 

#somediv:hover {
    opacity: 0;
}

/* Gradient  */
@mixin gradient($origin: left, $start: #ffffff, $stop: #000000){
    background-color: $start;
    background-image: -webkit-linear-gradient($origin, $start, $stop);
    background-image: -moz-linear-gradient($origin, $start, $stop);
    background-image: -o-linear-gradient($origin, $start, $stop);
    background-image: -ms-linear-gradient($origin, $start, $stop);
    background-image: linear-gradient($origin, $start, $stop);
}

/* Gradient Example */
#someDiv {
    @include gradient(left, #663333, #333333);
} 


